import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
		fetchPortalLink: '/portalLink'
	},
	put: {
		updatePortalLink: '/portalLink'
	},
	post: {
		createPortalLink: '/portalLink'
	},
	delete: {
		destroyPortalLink: '/portalLink'
	}
}

export const portalLinkApi = buildApi('portalLink', routes, '/manager')

