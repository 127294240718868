<template>
<div class='staff' :class='{ disabled: staff.closed, locked: team.locked, working }'>
	<div class='info' @click='e=>contextMenu=e' @contextmenu='e=>contextMenu=e'>
		<transition name='fade'>
			<div v-if='working' class='loadingWrapper'><div>
				<Loading />
			</div></div>			
		</transition>
			<!--
			<div v-else class='blockButton'>
				<svg class='smallIcon'>
					<use xlink:href='#checkbox' />
				</svg>
			</div>
		-->
		<div>
			<div>
				<div class='name'><span>{{staff.lastname}}, {{staff.firstname}}</span></div>
				<div class='email'>{{staff.email}}</div>
			</div>
			<div>
				<div class='lastLogin'>{{lastLogin}}</div>
				<div v-if='staff.manager' class='manager'>MANAGER</div>
			</div>
		</div>
	</div>

	<TernaryToggle :value='staffTeam.teamExercises' @toggle='v=>toggle("teamExercises", v)' class='teamTern' />

	<TernaryToggle :value='staffTeam.teamTemplates' @toggle='v=>toggle("teamTemplates", v)' class='teamTern'  />

	<TernaryToggle :value='staffTeam.teamEducation' @toggle='v=>toggle("teamEducation", v)' class='teamTern'  />

	<TernaryToggle :value='staffTeam.teamOutcomes' @toggle='v=>toggle("teamOutcomes", v)' class='teamTern' />

	<div class='teamClients'>
		<DraggableSwitch
			:value='staffTeam.teamClients'
			:clickable='true'
			@input='v=>toggle("teamClients", v)'
		/>
	</div>

	<div class='teamSwap' >
		<DraggableSwitch
			:value='staffTeam.teamSwap'
			:clickable='true'
			@input='v=>toggle("teamSwap", v)'
		/>
	</div>

	<div class='allowPHI' :class="{ siblingLocked: siblingTeamLocked }">
		<DraggableSwitch
			:value='staff.allowPHI'
			:clickable='true'
			@input='v=>toggle("allowPHI", v)'
		/>
	</div>

	<div class='secondaryAuth' :class="{ siblingLocked: siblingTeamLocked }">
		<DraggableSwitch
			:value='staff.secondaryAuth'
			:clickable='true'
			@input='v=>toggle("secondaryAuth", v)'
		/>
	</div>

	<template v-if='Object.keys($store.state.teams.teams).length > 1'>

		<TernaryToggle :value='staff.orgExercises' @toggle='v=>toggle("orgExercises", v)' class='orgTern' :class="{ siblingLocked: siblingTeamLocked }" />

		<TernaryToggle :value='staff.orgTemplates' @toggle='v=>toggle("orgTemplates", v)' class='orgTern' :class="{ siblingLocked: siblingTeamLocked }" />

		<TernaryToggle :value='staff.orgEducation' @toggle='v=>toggle("orgEducation", v)' class='orgTern' :class="{ siblingLocked: siblingTeamLocked }" />

		<TernaryToggle :value='staff.orgOutcomes' @toggle='v=>toggle("orgOutcomes", v)' class='orgTern' :class="{ siblingLocked: siblingTeamLocked }" />

	</template>

	<ContextMenu 
		v-if='contextMenu' 
		:e='contextMenu'
		@close='contextMenu=false'
	>
		<div class='iconTextButton neutral nameLabel'>
			<svg class='smallIcon'>
				<use xlink:href='#clients' />
			</svg>				
			<span class='label'>{{staff.lastname}}, {{staff.firstname}}</span>
		</div>
		<div 
			v-if='!staff.closed'
			class='textButton neutral'
			@click='save({ closed: true })'
		>
			<span class='label'>
				<template v-if="!staff.manager">Turn Off</template>
				<template v-else>Revoke Clinician Access</template>
			</span>
		</div>
		<div 
			v-else
			class='textButton neutral'
			@click='save({ closed: false })'
		>
			<span class='label'>Grant Clinician Access</span>
		</div>		

		<template v-if='staff.id!==$store.state.profile.user.id'>
			<div 
				v-if='!staff.manager'
				class='textButton neutral'
				@click='save({ manager: true })'
			>
				<span class='label'>Grant Manager Access</span>
			</div>		

			<div 
				v-else
				class='textButton neutral'
				@click='save({ manager: false })'
			>
				<span class='label'>Revoke Manager Access</span>
			</div>	
		</template>	

		<div class='changeTeamWrapper' :class='{ disabled: !joinTeams.length || (staff.teams && staff.teams.length>=3) || staff.closed }'>	
			<select class='changeTeam' @click.stop @change='joinTeam'>
				<option selected disabled>Join Team...</option>
				<option v-for='(team, i) in joinTeams' :key='i' :value='team.id'>{{team.title}}</option>
			</select>	
		</div>	

		<div class='changeTeamWrapper' :class='{ disabled: leaveTeams.length <= 1 || staff.closed }'>	
			<select class='changeTeam' @click.stop @change='leaveTeam'>
				<option selected disabled>Leave Team...</option>
				<option v-for='(team, i) in leaveTeams' :key='i' :value='team.teamId'>{{team.title}}</option>
			</select>	
		</div>

	</ContextMenu>


</div>
</template>

<script>
import { dateTime } from '@/components/common/mixins/dateTime'
import { staffApi } from '@/services/api/modules/manager/staff'
import { smallModalMixin } from '@/components/common/mixins/modal'
import DraggableSwitch from '@/components/common/DraggableSwitch'
import ContextMenu from '@/components/common/ContextMenu'
import Loading from '@/components/common/Loading'
import TernaryToggle from './TernaryToggle'
// import AddSeats from './AddSeats'
import AlertModal from '@/components/common/modals/Alert'
import ConfirmMoveClients from './ConfirmMoveClients'
import StaffPicker from './StaffPicker'

export default {
	name: 'Staff',
	mixins: [smallModalMixin, dateTime],
	components: { DraggableSwitch, ContextMenu, Loading, TernaryToggle },
	props: ['staff', 'team'],
	data: () => ({ contextMenu: false, ternaryContextMenu: false, working: false }),
	computed: {
		lastLogin() { 
			this.langBackdoor
			return this.staff.lastLogin ? this.date(this.staff.lastLogin) : ''
		},			
		staffTeam() {
			return this.staff.teams.find(t=>t.teamId===this.team.id) || {}
		},
		joinTeams() {
			const ids = this.staff.teams ? this.staff.teams.map(team=>team.teamId) : []
			return Object.values(this.$store.state.teams.teams).filter(team=>!ids.includes(team.id))
		},
		leaveTeams() {
			return this.staff.teams || []
		},
		siblingTeamLocked() {
			return this.staff.teams && this.staff.teams.some(t=>this.$store.state.teams.teams[t.teamId].locked)
		}
	},
	methods: {
		async save(payload) {
			console.log('here', this.$store.state.profile.organization.teamInvitations)
			if (payload.closed===false && this.staff.closed && this.$store.state.profile.organization.teamInvitations===0) {
				return this.$store.dispatch('flash/showAlert', 'maxStaff')
			//	return this.showSmallModal(AddSeats)
			}
			try {
				this.working = true
				await this.$store.dispatch('staff/save', { staffId: this.staff.id, payload })
				if (this.staff.id === this.$store.state.profile.user.id) await this.$store.dispatch('profile/fetch')
				if (payload.closed===true && this.staff.manager) {
					this.showSmallModal(AlertModal, { 
						title: 'Heads up', 
						message: 'This user still has Manager account access at no-charge.<br /><br />Learn more about enabling / disabling Manager account permissions <a href="https://support.simpleset.net/article/78-manage-user-permissions" target="_blank">here...</a>'
					})
				}
				this.$store.dispatch('teams/fetch')
				//if (!err) {
					/*
					if (payload.enabled && !this.staff.enabled) {
						this.$store.dispatch('profile/decInvitations', 1)
						this.$store.dispatch('teams/fetch')
					} else if (payload.enabled===false && this.staff.enabled) {
						this.$store.dispatch('profile/incInvitations', 1)
						this.$store.dispatch('teams/fetch')
					}			
					*/		
				//}
			} finally {
				this.working = false			
			}
		},	
		async toggle(prop, val) {
			const payload = {}
			payload[prop] = val
			try {
				this.working = true			
				await this.$store.dispatch('staff/save', { staffId: this.staff.id, teamId: this.team.id, payload })
				//if (prop==='teamClients' && val) this.$store.dispatch('teams/fetch')
				if (this.staff.id === this.$store.state.profile.user.id) await this.$store.dispatch('profile/fetch')
			} finally {
				this.working = false							
			}
//			await this.save(payload)
		},

		async joinTeam(e) { 
			const teamId = +e.target.value
			this.contextMenu = false
			try {
				this.working = true		
				await this.$store.dispatch('staff/joinTeam', { staffId: this.staff.id, teamId })
				await this.$store.dispatch('teams/fetch') 
				this.$store.dispatch('flash/showAction', `${this.staff.firstname} ${this.staff.lastname} joined to ${this.$store.state.teams.teams[teamId].title}.`)
			} finally {
				this.working = false							
			}			
		},
		async leaveTeam(e) {
			const teamId = +e.target.value
			this.contextMenu = false
			try {
				this.working = true		
				await this.$store.dispatch('staff/leaveTeam', { staffId: this.staff.id, teamId })
				await this.$store.dispatch('teams/fetch') 
				if (teamId===this.team.id) this.$emit('removeFromTeam', this.staff.id)
				this.$store.dispatch('flash/showAction', `${this.staff.firstname} ${this.staff.lastname} unjoined from ${this.$store.state.teams.teams[teamId].title}.`)
			} finally {
				this.working = false							
			}					
		}

	}
}
</script>

<style lang='scss'>
.teamsManager .staff {
	display: flex;
	align-items: center; 
	height: 40px;
	/*
	&:not(.disabled):hover, &:not(.locked):hover { 
		background: $color-focus; 
		.info { background: $color-focus !important; }
	}
	*/
	/*
	&.working > div { opacity: .8; pointer-events: none; }
	*/
	&:hover {
		background: $color-focus; 
		.info { background: $color-focus !important; }
		&:not(.disabled) {
			.teamClients, .teamSwap, .orgTern:not(.siblingLocked), .teamTern, .secondaryAuth:not(.siblingLocked), .allowPHI:not(.siblingLocked) {  background: $color-focus !important; }
		}
	}
	.info {
		position: sticky;
		left: 21px;		
		flex: 3;
		z-index: 2;
		min-width: 300px;
		font-size: $size-font-standard;
		background: #fff;
		height: 40px;
		display: flex;
		align-items: center;
		border-right: 1px solid $color-neutral-shadow;	
		border-left: 1px solid transparent;
		display: flex;
		align-items: center;
		cursor: pointer;		
		> div:first-child { 
			flex: 0; 
			position: relative;
			.blockButton { transform: scale(0.8); }
			svg { color: $color-neutral-dark; }
		}
		> div:last-child { 
			user-select: all; flex: 1; 
			margin-left: $size-gutter * 2; 
			display: flex;
			align-items: flex-start;
			> div { 
				white-space: nowrap;
				flex: 1; 
			}
		}
		.email { color: $color-primary-accent; font-size: $size-font-small; }
		.name {
			display: flex;
			align-items: flex-end;
		}
		.lastLogin {
			text-align: right;
			font-size: $size-font-small; margin-left: auto; margin-right: $size-gutter * 2; color: $color-neutral-dark;
		}
		.manager {
			text-align: right;
			font-size: $size-font-standard; font-weight: bold; color: $color-success;
			padding-right: $size-gutter * 2;
		}
		.loadingWrapper { 
			position: absolute !important;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #fff;
			.spinner { transform: scale(0.5); }								
		}

		&:after {
			position: absolute;
			top: 0;
			left: -8px;
			width: 7px;
			height: 41px;
			background: $color-primary-accent;
			content: '';
		}
		&:before {
			position: absolute;
			top: 0;
			left: -28px;
			width: 21px;
			height: 41px;
			background: $color-neutral-panel;
			content: '';
		}		
	}

/*
	> .teamClients, > .orgOutcomes {
		border-right: 1px solid $color-neutral;		
	}
*/
	> div:not(.info) { 
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		cursor: pointer;
		.teamClients, .teamSwap, .teamTern { background: #fff; }
	}

	.allowPHI {
		background: $color-neutral-silver;
		border-left: 1px solid $color-neutral-shadow;
	}
	.secondaryAuth {
		background: $color-neutral-silver;		
	}
	.orgTern { background: $color-neutral-silver;}

.teamClients, .teamSwap, .teamTern { background: #fff; }

	.nameLabel {
		cursor: auto;
		svg { color: $color-primary-accent; }
		.label { color: $color-primary-accent; }
		border-bottom: 1px solid $color-neutral-shadow;
	}

	.ternaryLabel .label {
		white-space: wrap;
		display: block;
		span { 
			display: block; white-space: nowrap; 
			&:last-child {
				font-size: $size-font-small;
				color: $color-black;
			}
		}

	}

	.ternaryTitle { display: block; }

	/* .draggableSwitch { pointer-events: none; } */

	&.disabled {
		.info {
			color: $color-alert;
/*			background: $color-neutral-silver !important;	*/
			.email { color: $color-alert; }
		}
		>div:not(.info):not(.contextMenuWrapper) {
			pointer-events: none;
			/*background: $color-neutral-silver !important; */
			> div { opacity: 0.5;  }
		}
		.nameLabel {
			svg { color: $color-alert; }
			.label { color: $color-alert; }
		}		
	}

	&.locked {
		>div:not(.info):not(.contextMenuWrapper) {
			pointer-events: none;
/*			background: $color-neutral-silver !important; */
			> div { opacity: 0.5;  }
		}
		.nameLabel {
			svg { color: $color-alert; }
			.label { color: $color-alert; }
		}				
	}

	.siblingLocked {
		pointer-events: none;	
		> div { opacity: 0.5; }
	}

	.changeTeamWrapper { display: flex; &.disabled { pointer-events: none; opacity: 0.5; }}
	.changeTeam {
		height: $size-control-height;
		cursor: pointer;
		text-align: center;
		max-width: 150px;
		margin: 0 auto;
	}

}
</style>