<template>
<div class='teamChooser'>
	<transition name='ltrtl'>
		<div v-if='list.length>2' class='content'>

			<div class='scrollContainer'>


				<div class='iconTextButton tagPrimary' @click='$emit("addTeam")'>
					<svg class='smallIcon'>
						<use xlink:href='#plus' />
					</svg>
					<span class='label'>Team</span>
				</div>

				<div class='teams'>
					<draggable 
						v-model='list' 
						v-bind='{ animation: 200, ghostClass: "ghost" }'
						@start='onDragStart'					
						@end='onDragEnd'
						@change='onMoved'
					>							
						<div v-for='(teamId, i) in list' :key='teamId' @click='select(teamId, i)' :class='{ selected: teamId===selected }' class='chooseTeam'>
							<!--
							<div class='blockButton dragHandle'>
								<svg class='smallIcon dragHandle'>
									<use xlink:href='#dragY' />
								</svg>
							</div>
						-->
							<div>{{$store.state.teams.teams[teamId].title}}</div>
						</div>
					</draggable>

				</div>

			</div>

		</div>
	</transition>
</div>
</template>

<script>
import { teamsApi } from '@/services/api/modules/manager/teams'
import draggable from 'vuedraggable'
import Scroll from '@/components/common/Scroll'

export default {
	name: 'TeamChooser',
	components: { Scroll, draggable },
	data() { return {
		selected: null,
		dragging: false
	}},
	computed: {
		list: { 
			get() { return this.$store.state.teams.list },
			set(v) { 
				this.$store.commit('teams/setList', v) 
			}
		}
	},
	methods: {
		select(teamId, i) {
			this.selected=teamId
			this.$emit('select', i)
		},
		onDragStart() { this.dragging=true },
		onMoved(v) {
			const i = v.moved.newIndex
			this.$emit('moved')
			setTimeout(async ()=>{
				const teamId = this.list[i]
				this.select(this.list[i], i)
				if (i===0) {
					const targetId = this.list[1]
					await teamsApi.touch('moveLeft', { teamId, targetId })
				} else {
					const targetId = this.list[i-1]
					await teamsApi.touch('moveRight', { teamId, targetId })
				}
			}, 0)
		},
		onDragEnd(a,b,c) { 
			this.dragging=false 
		}
	},
	mounted() { 
	}
}
</script>

<style lang='scss'>
.teamChooser {
	
	&:has(>div) {
		margin-right: 1px;
	}
	
	.scrollContainer { padding: 0 !important; margin: 0 !important; }
	.scrollContent { padding: 0 !important; margin: 0 !important; }

	.content {
		position: relative;
		height: 100%;
		width: 200px;	

		> div { position: absolute; top: 0; left: 0; width: 100%; height: 100%;  }
	}

	.iconTextButton {
		margin-top: $size-gutter * 2;
		height: 60px;
	}

	.teams {
 		margin-top: $size-gutter * 2;
		.chooseTeam {
			min-height: $size-control-height;
			font-size: $size-font-small;
			line-height: $size-lineHeight-small;
			display: flex;
			align-items: center;
			font-weight: normal;
			cursor: move;
			&:hover { background: $color-focus; } 
			&.selected { 
				color: $color-primary-accent; font-weight: bold; 
			}
			svg { color: $color-neutral-dark; transform: scale(0.9); }
			padding: $size-gutter $size-gutter * 3;
			.blockButton { cursor: move; }
			display: flex;
			align-items: center;
			>div:last-child { flex: 1; }
		}

	}



	.ghost {
		opacity: 0 !important;
	}	

}
</style>