<template>
<div class='teamWrapper'>
<div class='team' :class='{ selected: selected && loaded, locked: team.locked }'>
	<div class='teamHeader'>

		<div>

			<div class='teamDesc'>

				<div @click.stop>
					<div class='addStaffButton iconTextButton tagPrimary' @click.stop='$emit("addStaff", team.id)'>
						<svg class='smallIcon'>
							<use xlink:href='#plus' />
						</svg>
						<span class='label'>Clinician</span>
					</div>
					<div  v-if='!team.staffCount && !team.clientCount && !team.objCount' class='deleteButton iconTextButton tagAlert' @click.stop='destroy'>
						<svg class='smallIcon'>
							<use xlink:href='#delete' />
						</svg>
						<span class='label'>Delete</span>
					</div>										
				</div>				

				<div>

					<div v-if='loading' class='loadingWrapper'><div>
						<Loading />
					</div></div>

					<div v-else class='angle' @click='onSelect(false)'>
						<div>
							<svg class='smallIcon'>
								<use xlink:href='#angleRight' />
							</svg>			
						</div>
					</div>

					<div @click='onSelect(false)' class='titleWrapper'>
						<div class='title'>
							<span>{{team.title}}</span>
						</div>
						<div class='noStaff'><span>{{team.staffCount}} Clinician<template v-if="team.staffCount !== 1">s</template>,</span> <span>Seats in Use: {{team.staffEnabled}}</span></div>
						<!--
						<div class='noClients'>{{team.clientCount}} Client(s)</div>
						<div class='noObjs'>{{team.objCount}} File(s) (Exercises, Templates, etc)</div>
					-->
					</div>

					<svg class='teamSettings smallIcon' @click.stop='$emit("settings", team.id)'>
						<use xlink:href='#edit' />
					</svg>	

					<svg v-if='team.locked' class='locked smallIcon'>
						<use xlink:href='#lock' />
					</svg>						

				</div>

			</div>

			<div class='teamDefaults' :style='`flex: ${Object.keys($store.state.teams.teams).length > 1 ? "12" : "8"}`'>

				<div class='sharingSection teamSharing'>
					<div><div class='shareTitle'>Team Sharing</div></div>
					<div>
						<div @click='showDetail("teamExercises", true)'>Exercises</div>
						<div @click='showDetail("teamTemplates", true)'>Templates</div>
						<div @click='showDetail("teamEducation", true)'>Education</div>
						<div @click='showDetail("teamOutcomes", true)'>Outcomes</div>
						<div @click='showDetail("teamClients", true)'>Clients</div>
					</div>
					<div>
						<div>
							<TernaryToggle :value='team.teamExercises' @toggle='v=>save("teamExercises", v)' />
						</div>
						<div>
							<TernaryToggle :value='team.teamTemplates' @toggle='v=>save("teamTemplates", v)' />
						</div>
						<div>
							<TernaryToggle :value='team.teamEducation' @toggle='v=>save("teamEducation", v)' />
						</div>
						<div>
							<TernaryToggle :value='team.teamOutcomes' @toggle='v=>save("teamOutcomes", v)' />
						</div>
						<div>
							<DraggableSwitch
								:value='team.teamClients'
								:clickable='true'
								@input='v=>save("teamClients", v)'
							/>
						</div>				
					</div>
				</div>	
				<div class='toggles'>
					<div />
					<div>
						<div @click='showDetail("swapUsers", true)'>Swap<br />Users</div>
						<div @click='showDetail("clientManager", true)'>Client<br />Manager</div>
						<div @click='showDetail("2fa", true)'>2FA</div>
					</div>
					<div>
						<div>
							<DraggableSwitch
								:value='team.teamSwap'
								:clickable='true'
								@input='v=>save("teamSwap", v)'
							/>
						</div>	
						<div class='allowPHIdefault'>
							<DraggableSwitch
								:value='team.allowPHI'
								:clickable='true'
								@input='v=>save("allowPHI", v)'
							/>
						</div>
						<div>
							<DraggableSwitch
								:value='team.secondaryAuth'
								:clickable='true'
								@input='v=>save("secondaryAuth", v)'
							/>
						</div>																						
					</div>
				</div>
				<div v-if='Object.keys($store.state.teams.teams).length > 1' class='sharingSection orgSharing'>
					<div><div class='shareTitle'>
						Organization Sharing
						<svg class='detail' @click='showOrgDetail'>
							<use xlink:href='#detail' />
						</svg>
					</div></div>
					<div>
						<div @click='showDetail("orgExercises", true)'>Exercises</div>
						<div @click='showDetail("orgTemplates", true)'>Templates</div>
						<div @click='showDetail("orgEducation", true)'>Education</div>
						<div @click='showDetail("orgOutcomes", true)'>Outcomes</div>
					</div>
					<div>
						<div>
							<TernaryToggle :value='team.orgExercises' @toggle='v=>save("orgExercises", v)' />
						</div>
						<div>
							<TernaryToggle :value='team.orgTemplates' @toggle='v=>save("orgTemplates", v)' />
						</div>
						<div>
							<TernaryToggle :value='team.orgEducation' @toggle='v=>save("orgEducation", v)' />
						</div>
						<div>
							<TernaryToggle :value='team.orgOutcomes' @toggle='v=>save("orgOutcomes", v)' />
						</div>			
					</div>				
				</div>	

			</div>

		</div>

		<div v-if='selected && loaded' class='teamStaffHeader'>
			<div class='filter'>
				<div>
					<SearchInput 
						v-model='search'
						@input='debouncedFetch'
						@submit='fetchTop'
						placeholder='Filter Clinicians...'
					/>
				</div>
				<div>
					<div @click='enabled=!enabled'>
						<CheckButton :checked='enabled' />
						<div>Enabled</div>
					</div>
					<div @click='disabled=!disabled'>
						<CheckButton :checked='disabled' />
						<div>Disabled</div>				
					</div>
				</div>
			</div>
			<div class='sharingSection teamSharing'>
				<div><div>Team Sharing</div></div>
				<div>
					<div @click='showDetail("teamExercises")'>Exercises</div>
					<div @click='showDetail("teamTemplates")'>Templates</div>
					<div @click='showDetail("teamEducation")'>Education</div>
					<div @click='showDetail("teamOutcomes")'>Outcomes</div>
					<div @click='showDetail("teamClients")'>Clients</div>
				</div>
			</div>	
			<div class='togglesTeam'>
				<div @click='showDetail("swapUsers")'>Swap<br />Users</div>
			</div>
			<div class='togglesUser'>
				<div @click='showDetail("clientManager")'>Client<br />Manager</div>
				<div @click='showDetail("2fa")'>2FA</div>
			</div>
			<div v-if='Object.keys($store.state.teams.teams).length > 1' class='sharingSection orgSharing'>
				<div><div>
					Organization Sharing
					<svg class='detail' @click='showOrgDetail'>
						<use xlink:href='#detail' />
					</svg>
				</div></div>
				<div>
					<div @click='showDetail("orgExercises")'>Exercises</div>
					<div @click='showDetail("orgTemplates")'>Templates</div>
					<div @click='showDetail("orgEducation")'>Education</div>
					<div @click='showDetail("orgOutcomes")'>Outcomes</div>
				</div>
			</div>				
		</div>

	</div>

	<div v-if='selected && loaded' class='teamStaff'>
		<Staff
			v-for='staff in staffList' 
			:key='staff.id' 
			:staff='staff'
			:team='team'
			@removeFromTeam='removeFromTeam'
		/>
		<AtBottom v-if='staffList.length<total' @atBottom='fetchNext'>
			<Loading />
		</AtBottom>
	</div>

</div>
</div>
</template>

<script>
import { debounce, uniq, without } from 'lodash'
import { UI } from '@/utilities'
import { teamsApi } from '@/services/api/modules/manager/teams'
import { staffApi } from '@/services/api/modules/manager/staff'
import { smallModalMixin, largeModalMixin } from '@/components/common/mixins/modal'
import ConfirmDeleteModal from '@/components/common/modals/ConfirmDelete'
import Loading from '@/components/common/Loading'
import DraggableSwitch from '@/components/common/DraggableSwitch'
import AlertModal from '@/components/common/modals/Alert'
import AtBottom from '@/components/common/AtBottom'
import CheckButton from '@/components/common/buttons/Check'
import SearchInput from '@/components/common/SearchInput'
import TernaryToggle from './TernaryToggle'
import Staff from './Staff'

export default {
	name: 'Team',
	mixins: [smallModalMixin, largeModalMixin],
	components: { Loading, DraggableSwitch, TernaryToggle, Staff, AtBottom, CheckButton, SearchInput },
	props: ['team'],
	data() { return {
		selected: false,
		staff: [],
		loading: false,
		loaded: false,
		search: '',
		enabled: true,
		disabled: true,
		total: null,
		ui: null,
		debouncedFetch: debounce(function(search) {
			this.search = search 
			this.fetchTop()
		}.bind(this), 1000, { leading: false, trailing: true })			
	}},
	computed: {
		staffList() { 
			return this.staff.map(id=>this.$store.state.staff.staff[id])
		},
		enabledDisabled() {
			return { enabled: this.enabled, disabled: this.disabled }
		}		
	},
	methods: {
		async fetchTop() {
			this.debouncedFetch.flush()
			this.loading=true
			const [err, result] = await this.$store.dispatch('staff/fetch', { 
				teamId: this.team.id, 
				sort: 'lastname', 
				sortDir: 'asc', 
				limit: 50,
				search: this.search,
				...this.enabledDisabled
			})
			this.loading=false
			this.loaded=true
			if (!err) {
				this.total = result.total
				this.staff = result.items.map(i=>i.id)
			}
		},
		async fetchNext() {
			if (this.staff.length===this.total) return
			this.loading=true
			const [err, result] = await this.$store.dispatch('staff/fetch', { 
				teamId: this.team.id, 
				sort: 'lastname', 
				sortDir: 'asc', 
				skip: this.staff.length, 
				limit: 50,
				search: this.search,
				...this.enabledDisabled
			})
			this.loading=false
			if (!err) {
				this.staff.push(...result.items.map(i=>i.id))
			}
		},		
		removeFromTeam(staffId) {
			this.staff=this.staff.filter(id=>id!==staffId)
			this.total--
		},
		async save(prop, val) {
			const payload = { id: this.team.id }
			payload[prop] = val
			this.loading = true
			await this.$store.dispatch('teams/save', payload)
			this.loading = false
		},
		destroy() {
			const items = this.team.title
			const fn = async () => {
				try {
					this.loading = true
					const [err] = await teamsApi.touch('destroy', { id: this.team.id })
					if (!err) {
						this.$emit('destroyed')
						this.$store.commit('teams/removeTeam', this.team.id)
					}
				} finally {
					this.loading = false
				}
			}
			this.showSmallModal(ConfirmDeleteModal, { items, fn })
		},		
		onSelect(snap=true) {
			const isElementInScrollableViewport = (el, parentEl) => {
				var rect = el.getBoundingClientRect()
				var parentRect = parentEl.getBoundingClientRect()
				return (
					rect.top >= parentRect.top &&
					rect.bottom <= parentRect.bottom
				)
			}
			this.selected = this.team.staffCount ? !this.selected : false
			if (this.selected) {
				this.$emit('closeIfNot', this.team.id)
				setTimeout(()=>{
					const visible = isElementInScrollableViewport(this.$el, this.$el.closest('.scrollContainer'))
					if (snap || !visible) this.$el.scrollIntoView(true)
				}, 0)
			}
		},
		showOrgDetail() {
			this.showSmallModal(AlertModal, { 
				title: 'Organization Sharing', 
				message: 'Use these permissions to share Exercises, Templates, Education and Outcomes with ALL Staff on ALL Teams.'
			})
		},
		showDetail(type, def) {
			let title, message 
			if (type==="teamExercises") {
				title="Team Exercises"
				message="This permission controls whether or not a user is able to access shared Team Exercises."
			} else if (type==="teamTemplates") {
				title="Team Templates"
				message="This permission controls whether or not a user is able to access shared Team Templates."
			} else if (type==="teamEducation") {
				title="Team Education"
				message="This permission controls whether or not a user is able to access shared Team Education."
			} else if (type==="teamOutcomes") {
				title="Team Outcomes"
				message="This permission controls whether or not a user is able to access shared Team Outcomes."
			} else if (type==="teamClients") {
				title="Team Clients"
				message="This permission controls whether or not a user is able to access shared Team Clients."
			} else if (type==="swapUsers") {
				title="Swap Users"
				message="This permission allows users to easily swap between accounts -- useful on shared terminals."
			} else if (type==="clientManager") {
				title="Client Manager"
				message="This permission controls whether or not a user is able to input client information / protected health information."
			} else if (type==="2fa") {
				title="Two-Factor Authentication"
				message="This permission enables two-factor authentication on a user login."
			} else if (type==="orgExercises") {
				title="Organization Exercises"
				message="This permission controls whether or not a user is able to access shared Organization Exercises."
			} else if (type==="orgTemplates") {
				title="Organization Templates"
				message="This permission controls whether or not a user is able to access shared Organization Templates."
			} else if (type==="orgEducation") {
				title="Organization Education"
				message="This permission controls whether or not a user is able to access shared Organization Education."
			} else if (type==="orgOutcomes") {
				title="Organization Outcomes"
				message="This permission controls whether or not a user is able to access shared Organization Outcomes."
			}
			if (def) message+="<br /><br />This permission is a Team default.  Clinicians will automatically inherit this permission when they join the team."
			this.showSmallModal(AlertModal, { title, message })			
		}	
	},
	watch: {
		selected(v) {
			if (this.team.staffCount) this.$emit('selected', { id: this.team.id, value: v })
			if (v) this.fetchTop()
			else this.loaded = false
			/*
			let teamsSelected = this.ui.get('teamsSelected') || []
			if (v) teamsSelected = uniq([ ...teamsSelected, this.team.id ])
			else teamsSelected = without(teamsSelected, this.team.id)
			this.ui.set('teamsSelected', teamsSelected)
			*/
			if (v) this.ui.set('teamsSelected', this.team.id)
		},
		enabled(v) {
			if (!v && !this.disabled) this.disabled = true
			this.ui.set('teamStaffEnabled_'+this.team.id, v)
		},
		disabled(v) {
			if (!v && !this.enabled) this.enabled = true
			this.ui.set('teamStaffDisabled_'+this.team.id, v)				
		},
		async enabledDisabled() { if (this.selected) await this.fetchTop() },
	},
	async mounted() {
		this.ui = new UI(this.$store.state.profile.user.id)
		const enabled = this.ui.get('teamStaffEnabled_'+this.team.id)	
		const disabled = this.ui.get('teamStaffDisabled_'+this.team.id)
		if (typeof enabled==='boolean') this.enabled = enabled
		if (typeof disabled==='boolean') this.disabled = disabled	
		const teamsSelected = this.ui.get('teamsSelected')
		if (teamsSelected===this.team.id) this.onSelect(false)
	}
}
</script>

<style lang='scss'>
.teamsManager .teamWrapper { padding-top: $size-gutter * 2; }
.teamsManager .team {

	&:not(.selected) {
		.teamHeader {
			border-top: 1px solid $color-neutral-shadow;
			border-right: 1px solid $color-neutral-shadow;		
			border-bottom: 1px solid $color-neutral-shadow;	

		}
	}
	&.selected {
		.teamHeader {
			background: $color-neutral-panel;
			.angle { transform: rotate(90deg); }
			.teamDesc {
				border-top: 1px solid $color-neutral-shadow;
			}
			.teamDefaults {
				.sharingSection, .toggles, .teamDefaultsHeader {
					opacity: 0.5;
					pointer-events: none;	
				}
			}
		}
	}

	&.locked {
		.sharingSection, .toggles {
			opacity: 0.5;
			pointer-events: none;	
		}		
	}


	.teamHeader {
		position: sticky;
		top: -2px;
		z-index: 20;
		align-items: center;
		min-width: 1300px;
		border-left: 7px solid $color-primary-accent;
		background: #fff;


		> div:first-child {
			display: flex;
			align-items: center;
			background: inherit;
			height: 100%;

			.teamDesc {
				position: sticky;
				background: inherit;
				left: 21px;
				z-index: 20;
				flex: 3;
				min-width: 300px;
				height: 100%;
				display: grid;
				grid-gap: 1px;
				grid-template-rows: auto 1fr;
				border-right: 1px solid $color-neutral-shadow;
				background: $color-neutral-shadow;

				&:has(.iconTextButton:hover) {
					background: $color-focus;
					> div { background: $color-focus; }
				}

				> div { 
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #fff;
					min-width: 0;
					&:last-child {
						padding-right: $size-gutter * 2;
					}
				}

				&:after {
					position: absolute;
					top: 0;
					left: -7px;
					width: 7px;
					height: calc(100% + 1px);
					background: $color-primary-accent;
					content: '';
				}
				&:before {
					position: absolute;
					top: -8px;
					left: -28px;
					width: 21px;
					height: calc(100% + 23px);
					background: $color-neutral-panel;
					content: '';
				}					

				.teamSettings { cursor: pointer; margin-left: auto; min-width: 16px; }

				.locked { margin-left: $size-gutter; color: $color-neutral-dark; min-width: 16px; }

				.angle {
					cursor: pointer;
					flex: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					transition: transform 200ms linear;		
					flex-basis: 32px;
					margin: 0 14px;
					> div {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 32px;
						height: 32px;
						border-radius: 50%;
						svg { color: #fff; }
						background: $color-primary-accent;
					}				
				}

				.loadingWrapper {
					flex: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-basis: 60px;	
					min-width: 60px;			
					> div { 
						width: 24px; 
						height: 24px; 
						position: relative; 
					}			
					.loading { background: none; }
					.spinner { transform: scale(0.5); margin-right: 10px; }								
				}

				> div:first-child { 
					padding-top: 11px;
					padding-bottom: 10px;
					margin-right: auto;
				}
				> div:last-child {
					padding-top: 10px;
					padding-bottom: 11px;
				}
				> div { }

				.titleWrapper { min-width: 0; }

				.title {
					font-size: $size-font-large;
					color: $color-primary-accent;
					font-weight: bold;
					display: block !important; 
					white-space: nowrap; 
					overflow: hidden; text-overflow: ellipsis;
					margin-right: $size-gutter * 3;
				}

				.noStaff, .noClients, .noObjs {
					font-size: $size-font-standard;
					line-height: $size-lineHeight-standard;
					display: flex;
					align-items: flex-end;
					> span:last-child { margin-left: $size-gutter; }
				}		
				.noClients, .noObjs {
					font-size: $size-font-small;
					line-height: $size-lineHeight-small;
					color: $color-neutral-dark;					
				}
			}

			.teamControls {
				flex: 9;
				display: flex;
				border: 1px solid red;
			}

			.teamDefaults {
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				.teamDefaultsHeader {
					flex-basis: 100%;
					height: 30px;
					font-size: $size-font-standard;
					padding-left: $size-gutter * 3;					
					position: sticky;
					span { font-weight: bold; color: $color-primary-accent; }
				}
			}

			.sharingSection {

				&.teamSharing { 
					flex: 5; 
					> div:first-child:before {
						left: 10%;
						width: 80%;					
					}
				}
				&.orgSharing { 
					flex: 4; 
					> div:first-child:before {
						left: 12.5%;
						width: 75%;					
					}
				}

				> div:first-child {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: $size-font-standard;
					height: 24px;						
					position: relative;
					> div { 
						position: relative; 
						z-index: 3; 
						border: 1px solid $color-neutral-shadow;
						background: $color-neutral-silver;
						height: 24px;
						border-radius: 12px;
						padding: 0 $size-gutter * 2;
						display: flex;
						align-items: center;

					}
					&:before {
						position: absolute;
						z-index: 1;
						top: 50%;
						border-top: 1px solid $color-neutral-shadow;
						content: ''
					}		
				}
				
				> div:nth-child(2) {
					display: flex;
					align-items: center;
					justfy-content: center;
					height: $size-control-height;				
					font-size: $size-font-small;
					font-weight: bold;
					color: $color-primary-accent;
					text-align: center;
					margin: 1px 0;
					> div { 
						flex: 1; 
						display: flex;
						align-items: center;
						justify-content: center;
						height: $size-control-height;
						position: relative;		
						cursor: pointer;
						&:hover { text-decoration: underline; }		
						&:first-child, &:last-child {
							&:after {
								position: absolute;
								top: -13px;
								left: 50%;
								width: 1px;
								height: 7px;
								border-left: 1px solid $color-neutral-shadow;
								content: ''
							}			
						}		
					}	
				}

				> div:nth-child(3) {
					display: flex;
					align-items: center;
					justfy-content: center;
					height: 24px;			
					font-size: $size-font-small;
					font-weight: bold;
					color: $color-primary-accent;
					text-align: center;
					> div { 
						flex: 1; 
						display: flex;
						align-items: center;
						justify-content: center;
						height: $size-control-height;
						position: relative;					
					}
				}
			}

			.toggles {
				flex: 3;
				> div {
					display: flex;
					justify-content: center;
					font-size: $size-font-small;
					font-weight: bold;
					color: $color-primary-accent;
					&:nth-child(1) { height: 24px; }
					&:nth-child(2) { 
						height: $size-control-height;
						margin: 1px 0;
					}
					&:nth-child(3) { height: 24px; }
					> div { 
						flex: 1;
						display: flex;
						align-items: center;
						justify-content: center;
						text-align: center;
						cursor: pointer;
						&:hover { text-decoration: underline; }	
					}
				}
			}			

		}

		> div:last-child {
			flex: 1;
		}

		.deleteButton.hidden {
			pointer-events: none;
			opacity: 0;
		}

	}

	> .teamStaffWrapper {
	}

	&.selected {

	}

	.allowPHIdefault { padding-left: 1px; }

	svg.detail {
		color: $color-primary-accent;	
		background: #fff;
		height: 12px;
		border-radius: 50%;		
		width: 12px;
		margin-left: $size-gutter;
		margin-right: -$size-gutter;
		cursor: pointer;
	}


	.teamStaffHeader {
		display: flex;
		background: #fff;
		z-index: 20;

		background: #fff;
		border: 1px solid transparent;
		border-left: none;
		border-top: 1px solid $color-neutral-shadow;
		border-right: 1px solid $color-neutral-shadow;			
		border-bottom: none;

		.filter {
			flex: 3;
			min-width: 300px;		
			position: sticky;
			left: 21px;
			background: #fff;
			z-index: 10;
			border-right: 1px solid $color-neutral-shadow;
			border-left: 1px solid transparent;
			> div:first-child {

			}

			> div:last-child {
				display: flex;
				justify-content: center;	
				font-size: $size-font-standard;		
				height: $size-control-height; 
				> div {
					display: flex;
					align-items: center;
					&:last-child { margin-left: $size-gutter * 2; }
					cursor: pointer;
					.checkButton { margin-right: $size-gutter; pointer-events: none; }
					/*
					> div {
						display: flex;
						align-items: center;
						justify-content: center;
						> div:last-child { flex: 1; }
						.checkButton { margin-right: $size-gutter; }
					}
					*/
				}			
			}	

			&:after {
				position: absolute;
				top: -1px;
				left: -8px;
				width: 7px;
				height: 81px;
				background: $color-primary-accent;
				content: '';
			}
			&:before {
				position: absolute;
				top: -1px;
				left: -28px;
				width: 21px;
				height: 81px;
				background: $color-neutral-panel;
				content: '';
			}					
		}

		.sharingSection {



			&.teamSharing { 
				flex: 5; 
				> div:first-child:before {
					left: 10%;
					width: 80%;					
				}
			}
			&.orgSharing { 
				flex: 4; 
				background: $color-neutral-silver;
				> div:first-child:before {
					left: 12.5%;
					width: 75%;					
				}
			}

			> div:first-child {

				display: flex;
				align-items: flex-end;
				justify-content: center;
				font-size: $size-font-standard;
				height: $size-control-height;								
				position: relative;
				> div { 
					position: relative; 
					z-index: 3; 
					border: 1px solid $color-neutral-shadow;
					background: $color-neutral-silver;
					height: 24px;
					border-radius: 12px;
					display: flex;
					align-items: center;
					padding: 0 $size-gutter * 2;
					margin-bottom: 1px;
				}
				&:before {
					position: absolute;
					z-index: 1;
					top: calc(50% + 7px);
					border-top: 1px solid $color-neutral-shadow;
					content: ''
				}
			
			}
			> div:last-child {
				display: flex;
				align-items: center;
				justfy-content: center;
				height: $size-control-height;				
				font-size: $size-font-small;
				font-weight: bold;
				color: $color-primary-accent;
				text-align: center;
				> div { 
					flex: 1; 
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;
					height: $size-control-height;	
						cursor: pointer;
						&:hover { text-decoration: underline; }				
					&:first-child, &:last-child {
						&:after {
							position: absolute;
							top: -13px;
							left: 50%;
							width: 1px;
							height: 7px;
							border-left: 1px solid $color-neutral-shadow;
							content: ''
						}			
					}		
				}
			}
		}

		.togglesTeam, .togglesUser {
			display: flex;
			justify-content: center;
			font-size: $size-font-small;
			font-weight: bold;
			color: $color-primary-accent;
			height: 80px;
			> div { 
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				margin-top: auto;
				height: $size-control-height;	
						cursor: pointer;
						&:hover { text-decoration: underline; }				
			}
		}
		.togglesTeam { flex: 1; }
		.togglesUser { 
			flex: 2;
			background: $color-neutral-silver;
			border-left: 1px solid $color-neutral-shadow;
		}
	}

	.teamStaff {
		animation: slideDown 600ms $ease-out-quint;
		min-width: 1300px;
		position: relative;
		background: #fff;
		border: 1px solid $color-neutral-shadow;
		border-bottom: none;
		box-shadow: 0 1px 0 $color-neutral-shadow;
		border-left: 7px solid $color-primary-accent;
		border-top: none;
		margin-bottom: $size-gutter * 4;


	}


	.atBottom { height: 80px; position: relative; }

}
</style>