<template> 
<div class='uploadTeamLogo'>
	<div class='preview' v-if='logo'>
		<LazyImage :src='logo' :contain='true' />
		<CloseButton @click='$emit("unsetLogo")' />	
	</div>
	<UploadLogo v-else :getUploadUrl='getUploadUrl' @uploadSuccess='onSuccess' />
</div>
</template> 
 
<script>
import { teamsApi } from '@/services/api/modules/manager/teams'
import CloseButton from '@/components/common/buttons/Close'
import LazyImage from '@/components/common/LazyImage'
import UploadLogo from '@/components/clinician/settings/headerFooters/UploadLogo'

export default {
	name: 'UploadTeamLogo',
	components: { UploadLogo, CloseButton, LazyImage },
	props: ['logo'],
	data() { return {
		pending: null
	}},
	methods: {
		async getUploadUrl() {
			const result = await teamsApi.touch('uploadLogo')
			if (!result[0]) {
				this.pending = result[1].pendingResource
			}
			return result
		},
		onSuccess() {
			this.$emit('uploadSuccess', this.pending)
		}
	}
}
</script>

<style lang='scss'>
.uploadTeamLogo {
	position: relative;
	height: 100%;
	.preview {
		@include fill;
		display: flex;
		.closeButton { 
	
		}
	}
}
</style>