<template>
<SmallModal class='confirmMoveClients' @close='$emit("close")'>
	<template v-slot:title>{{$t("views.confirmMoveClients.title")}}</template>
	<template v-slot:message>
		<p v-html='p1' />
		<p v-html='p2' />
		<p v-html='p3' />
	</template>
	<template v-slot:buttons>
		<div class='textButton primary' @click='bringClients'>
			<span class='label'>{{btn1}}</span>
		</div>
		<div class='textButton primary' @click='stayClients'>
			<span class='label'>{{btn2}}</span>
		</div>
	</template>
</SmallModal>
</template>

<script>
import { fill } from '@/utilities'
import SmallModal from '@/components/common/modals/SmallModal'

export default {
	name: 'ConfirmMoveClients',
	components: { SmallModal },
	props: ['staffAccount', 'newTeam', 'oldTeam', 'fnBring', 'fnStay'],
	computed: {
		p1() { return fill(this.$t('views.confirmMoveClients.p1'), { staffAccount: this.staffAccount }) },
		p2() { return fill(this.$t('views.confirmMoveClients.p2'), { staffAccount: this.staffAccount, newTeam: this.newTeam }) },
		p3() { return fill(this.$t('views.confirmMoveClients.p3'), { oldTeam: this.oldTeam }) },
		btn1() { return fill(this.$t('views.confirmMoveClients.btn1'), { newTeam: this.newTeam }) },
		btn2() { return fill(this.$t('views.confirmMoveClients.btn2'), { oldTeam: this.oldTeam }) }
	},
	methods: {
		stayClients() {
			this.fnStay()
			this.$emit('close')
		},
		bringClients() {
			this.fnBring()
			this.$emit('close')
		}
	}
}
</script>

<style lang='scss'>
.confirmMoveClients {
	b { color: $color-primary-accent; }
}
</style>