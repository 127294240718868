<template>
<div class='addStaff'>
	
	<div class='content'>
		<Scroll>
			<div class='wrapper mainTitle'>
				<div>Invite Clinicians To:&nbsp;&nbsp;{{team.title}}</div>
			</div>			
			<div class='emails'>
				<div class='label'>
					Enter the email(s) of the Clinicians you want to invite:
					<span>Seats Available: {{invitations}}</span>
				</div>
				<EmailsInput 
					v-model='emails' 
					:placeholder='$t("views.staffManager.invite.phEmails")' 
					:maxtags='invitations === "∞" ? 20 : Math.min(20, invitations)'
				/>
				<div v-if='warnEmails.length' class='emailList'>
					<div class='warnHeader'>CAUTION &mdash; The following emails are already in use in SimpleSet:<br /><br /></div>
					<div v-for='(email, i) in warnEmails' :key='i' class='email'>{{email}}</div>
				</div>
			</div>
		</Scroll>
	</div>
	<div class='footer'>
		<div class='modalControls'>
			<div class='iconTextButton success' :class='{ disabled: !emails.length }' @click='addStaff'>
				<svg class='smallIcon'>
					<use xlink:href='#check' />
				</svg>
				<span class='label'>Invite</span>
			</div>
		</div>
	</div>
	<transition name='fade'><Loading v-show='loading' /></transition>
</div>
</template>

<script>
import { staffApi } from '@/services/api/modules/manager/staff'	
import { smallModalMixin } from '@/components/common/mixins/modal'
import AlertModal from '@/components/common/modals/Alert'
import Scroll from '@/components/common/Scroll'
import Loading from '@/components/common/Loading'
import EmailsInput from '@/components/common/EmailsInput'

export default {
	name: 'InviteStaff',
	mixins: [smallModalMixin],
	components: { Loading, EmailsInput, Scroll },
	props: ['teamId', 'cb'],
	data() { return { 
		emails: '',
		warnEmails: [],
		loading: false
	}},
	computed: {
		team() { return this.$store.state.teams.teams[this.teamId] },
		disabled() {
			return (
				!this.emails.length
			)
		},
		invitations() { return this.$store.state.profile.organization.teamInvitations }
	},
	methods: {
		addStaff() {
			setTimeout(async () => {
				if (!this.emails) return
				const payload = {
					emails: this.emails,
					teamId: this.teamId
				}
				this.loading=true
				try {
					const [err] = await staffApi.touch('invite', payload)
					if (!err) {
						this.$store.commit('profile/decOrgInvitations', this.emails.length)
						/*
						this.emails = ''
						this.showSmallModal(AlertModal, {
							title: this.$t('views.staffManager.invite.success.title'),
							message: 'Invitations have been sent by email.  Your Staff will automatically be added to your Team when they register.'
						})
						*/
						this.cb()
						this.$emit('close')
					} else {
						this.showSmallModal(AlertModal, {
							title: 'Invalid Email(s)',
							message: 'At least one of the emails you have entered is invalid.  Please correct the error and try again.'
						})
					}
				} finally {
					this.loading = false
				}
			}, 0)
		},
		async checkEmails() {
			try {
				console.log('here')
				const [err, result] = await staffApi.touch('checkEmails', { emails: this.emails })
				console.log(err)
				if (!err) {
					this.warnEmails = result.emails
				}
			} catch (err) { console.log(err) }
		}
	},
	watch: {
		emails(v) {
			if (v.length) this.checkEmails()
		}
	}
}
</script>

<style lang='scss'>
.addStaff {
	@include absTopLeft;
	@include fill;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr auto;
	background: $color-white;

	.header {
		padding-top: 40px;
		padding-bottom: 20px;
		font-size: 30px;
		line-height: 140%;
		color: $color-primary-accent;
		text-align: center;
	}

	.mainTitle {
		padding-top: 40px;
		font-size: 30px;
		width: 600px;
		margin: 0 auto;	
		margin-bottom: 20px;		
		> div {
			margin: 0 auto; 
		}
	}

	.content {
		position: relative; 
		display: flex; 
		justify-content: center; 
		.wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

/*
	.footer {
		padding: 14px;
		> div {
			height: $size-control-height * 2;
			background: $button-gradient-success;
			font-size: $size-font-large;
			font-weight: bold;
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			&.disabled {
				background: $color-neutral;
				color: $color-neutral-dark;
				pointer-events: none;
			}
		}
	}
*/

	.footer {
		display: flex;
		padding: $size-gutter * 2;
		> div { flex: 1; }
	}

	.emails {
		padding: 30px;
		flex: 1;
		margin: 0 10%;
		min-width: 600px;
		.label { 
			display: flex;
			margin-bottom: $size-gutter * 3;
			font-size: $size-font-large;
			line-height: 150%;
			span { margin-left: auto; }
		}
		.emailsInput {
			border: 1px solid $color-neutral-shadow;
			margin-bottom: $size-gutter * 4;
			box-shadow: 0 4px 6px -2px rgba(0,0,0,.08);
			input { min-height: 50px; }
		}
		.emailList {
			> div:first-child { color: $color-alert; }
			.email { margin-left: $size-gutter * 2; }
			font-size: $size-font-standard;
			line-height: $size-lineHeight-standard;
		}
	}

		.sendInvitations {
			height: $size-control-height * 2;
			background: $button-gradient-success;
			color: $color-white;
			font-size: $size-font-large;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			&.disabled {
				color: $color-neutral-shadow;
				background: $color-neutral-silver;
				border: 1px solid $color-neutral-shadow;
			}
		}

		.noInvitations { font-weight: bold; color: $color-alert; }
	
}
</style>