<template>
<div class='teamsManager' :class='{ hideAtBottom }'>
	<PendingStaff ref='pendingStaff' />
	<TeamChooser @select='selectTeam' @moved='renderKey++' @addTeam='showAddTeam' />
	<div>
		<Scroll ref='scroll'>
			<div class='wrapper' :key='renderKey'>
				<div class='addTeamButtonWrapper'>
					<div>
						<div class='addTeamButton iconTextButton tagPrimary' @click='showAddTeam'>
							<svg class='smallIcon'>
								<use xlink:href='#plus' />
							</svg>
							<span class='label'>Team</span>
						</div>
					</div>
					<div />		
				</div>
				<Team
					v-for='team in teams' 
					:key='team.id' 
					:team='team' 
					ref='teams'
					@addStaff='showAddStaff'
					@settings='showSettings'
					@destroyed='onDestroyed'
					@closeIfNot='closeIfNot'
				/>
				<div :style='`height: ${fillHeight-204}px`' />
			</div>
		</Scroll>
	</div>
	<transition name='fade'><Loading v-show='working' /></transition>
</div>
</template>

<script>
import { UI } from '@/utilities'
import { smallModalMixin, largeModalMixin } from '@/components/common/mixins/modal'
 import { onResizeMixin } from '@/components/common/mixins/onResize'
import AlertModal from '@/components/common/modals/Alert'
import Scroll from '@/components/common/Scroll'
import Loading from '@/components/common/Loading'
import Welcome from './Welcome'
//import AddSeats from './AddSeats'
import Team from './Team'
import AddTeam from './AddTeam'
import Huzzah from './Huzzah'
import AddStaff from './AddStaff'
import Settings from './Settings'
import PendingStaff from './PendingStaff'
import TeamChooser from './TeamChooser'

export default {
	name: 'Teams',
	mixins: [smallModalMixin, largeModalMixin, onResizeMixin],
	components: { Scroll, Team, PendingStaff, TeamChooser, Loading },
	data() { return { hideAtBottom: false, timer: null, renderKey: 0, fillHeight: 0, working: false }},
	computed: {
		teams() { return this.$store.state.teams.list.map(id=>this.$store.state.teams.teams[id]) }
	},
	methods: {
		selectTeam(i) {
			clearTimeout(this.timer)
			//this.hideAtBottom = true 
			//this.timer = setTimeout(()=>this.hideAtBottom=false, 2000)
			this.$refs.teams[i].$el.scrollIntoView(true)
			this.$refs.teams.forEach((team,index)=>{
				if (index===i && !team.selected) team.onSelect()
				else if (index!==i && team.selected) team.onSelect()
			})
			if (!this.$refs.teams[i].selected) this.$refs.teams[i].onSelect()
		},
		closeIfNot(teamId) {
			this.$refs.teams.forEach(team=>{
				if (team.team.id!==teamId) team.selected = false
			})
		},
		async fetch() { 
			this.working = true
			await this.$store.dispatch('teams/fetch') 
			this.working = false
		},
		showWelcome() {
			this.showMediumModal(Welcome, { 
				cb: this.showAddTeam,
				sticky: true
			}) 
		},
		showAddTeam() {
			const sticky = !this.teams.length
			this.showMediumModal(AddTeam, { 
				cb: async ({ id }) => {
					await this.fetch()
					if (sticky) {
						setTimeout(()=>this.$refs.teams[0].onSelect(), 0)
						await this.$store.dispatch('profile/fetch')
					}
					this.closeIfNot(-1)
				//	this.renderKey++
					this.showAddTeamSuccess(id, sticky)
					setTimeout(()=>this.$refs.scroll.scrollToTop(), 0)				
				//	if (this.teams.length===2) this.secondTeamAdded = true
				},
				addManagerToTeam: sticky,
				sticky
			}) 
		},
		showAddTeamSuccess(teamId, sticky) {
			const title = this.$store.state.teams.teams[teamId].title
			const message = [ `Next let's invite some Clinicians to your Team...` ]
			if (this.teams.length === 2) message.unshift(
				'Now that you have more than one Team, "Organization Sharing" has been enabled.',
				'Use Organization Sharing permissions to share Exercises, Templates, Education and Outcomes with ALL Clinicians on ALL Teams.'
			)
			this.showSmallModal(AlertModal, {
				title: 'Team Added',
				message,
				//label: `Invite Clinicians to: ${title}`,
				label: "Invite Clinicians",
				cls: 'primary',
				fn: () => this.showAddStaff(teamId),
				cancelLabel: this.teams.length > 1 ? "I'll do this later" : null,
			})
/*			
			this.showMediumModal(AddTeamSuccess, { 
				first: this.teams.length===1, 
				cb: () => {
					this.showAddStaff(teamId)
				}
			}, '50%') 
*/
		},
		showAddStaff(teamId, sticky) {
			if (this.$store.state.profile.organization.teamInvitations || this.$store.state.profile.organization.seats===null)	{
				this.showMediumModal(AddStaff, {
					teamId,
					cb: () => {
						this.$refs.pendingStaff.init()
						this.showHuzzah(teamId)
					},
					sticky
				})
			} else {
				this.$store.dispatch('flash/showAlert', 'maxStaff')
				//this.showSmallModal(AddSeats)
			}
		},
		showHuzzah(teamId) {
			this.showMediumModal(Huzzah, {
				cb: v=>{
					if (v) {
						this.showAddStaff(teamId)
					}
/*
					 else if (this.secondTeamAdded) {
						this.secondTeamAdded = false
						this.showSmallModal(AlertModal, {
							title: 'Heads Up - Organization Sharing Enabled',
							message: [
								'Now that you have multiple Teams, Organization Sharing has been enabled.',
								'Use Organization Sharing permissions to share Exercises, Templates, Education and Outcomes with ALL Staff on ALL Teams.'
							]
						})						
					}
					*/
				}
			}, '700px') 
		},
		showSettings(teamId) {
			this.showMediumModal(Settings, { 
				teamId,
				cb: this.fetch
			})
		},		
		onDestroyed() {
			if (this.$refs.pendingStaff.visible) {
				this.$refs.pendingStaff.init()
				this.$store.dispatch('profile/fetch')
			}
		},
		onResize() {
			this.fillHeight=window.innerHeight
		}
	},
	async mounted() {
		await this.fetch()
		if (!this.teams.length) {
			this.showWelcome()
		} else {
			const ui = new UI(this.$store.state.profile.user.id)
			const teamsSelected = ui.get('teamsSelected')
			if (!teamsSelected) this.$refs.teams[0].onSelect(false)
		}
	}
}
</script>

<style lang='scss'>


.teamsManager {
	@include absTopLeft;
	@include fill;
	display: grid;
	grid-template-columns: auto auto 1fr;
	grid-template-rows: 1fr;
	background: repeating-linear-gradient(
		180deg,
		darken($color-neutral, 5%),
		darken($color-neutral, 5%) 14px,
		$color-neutral-silver 14px,
		$color-neutral-silver 28px
	);
	background-position: 0 -14px;

	&.hideAtBottom .atBottom { display: none !important }

	> div { position: relative; background: $color-neutral-accent; }
/*
*/
	.scrollContainer {
		overflow-x: auto !important;
		


/*
		&::-webkit-scrollbar {
			display: block;
			height: $size-gutter * 3;
			cursor: move;
		}

		&::-webkit-scrollbar-track {}

		&::-webkit-scrollbar-thumb {
			box-shadow: inset 0 0 10px 10px;
			border: solid 3px transparent;
			border-radius: 11px;
			color: inherit;		
		}	
*/

		/*
		&::-webkit-scrollbar:horizontal {
		height: 0;
		width: 0;
		display: none;
		}

		&::-webkit-scrollbar-thumb:horizontal {
		display: none;
		}		
		*/
	}

	.scrollContent { padding-top: 0; }

	.wrapper {
		padding: $size-gutter * 2;
		padding-top: 0; 
		&:has(> :nth-child(4)) {
			.addTeamButtonWrapper > div:last-child { flex: 12; }
		}
		&:has(> :nth-child(5)) {
			.addTeamButton { display: none; }
		}
	}

	.addTeamButtonWrapper { 
		min-width: 1295px;
		display: flex; 
		margin-left: 7px;
		> div:first-child { flex: 3; min-width: 300px; position: sticky; left: 21px;}
		> div:last-child { flex: 8; }
	}

	.addTeamButton { 
		margin-top: $size-gutter * 2;
		height: 60px;
		background: #fff;
		border: 1px solid $color-neutral-shadow;
		position: sticky; 
		left: 21px;
		&:after {
			position: absolute;
			top: -1px;
			left: -8px;
			width: 7px;
			height: 60px;
			background: $color-primary-accent;
			content: '';
		}
	}
}
</style>