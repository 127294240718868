<template>
<div class='huzzah'>

	<div class='mainTitle'>
		<div>Invitations Sent &mdash; Huzzah!</div>
	</div>	

	<div class='content'>

		<div class='huzzah'>
			<div>
				<img src='/img/goodjob.jpg' />
				<div>Invitations have been sent by email.</div>
			</div>
		</div>

	</div>

	<div class='footer'>
		<div class='modalControls'>
			<div class='iconTextButton neutral' @click='onClick(false)'>
				<svg class='smallIcon'>
					<use xlink:href='#check' />
				</svg>
				<span class='label'>Done for Now</span>
			</div>
			<div class='iconTextButton success' @click='onClick(true)'>
				<svg class='smallIcon'>
					<use xlink:href='#check' />
				</svg>
				<span class='label'>Invite More</span>
			</div>
		</div>
	</div>

	<Confetti />

</div>
</template>

<script>
import Confetti from '@/components/common/Confetti'

export default {
	name: 'AddTeamSuccess',
	components: { Confetti },
	props: ['cb'],
	data: () => ({}),
	computed: {},
	methods: {
		onClick(v) {
			this.cb(v)
			this.$emit('close')
		}
	}
}
</script>

<style lang='scss'>
.huzzah {
	@include absTopLeft;
	@include fill;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr auto;

	.mainTitle {
		padding-top: 40px;
		font-size: 30px;
		width: 600px;
		margin: 0 auto;	
		margin-bottom: 20px;	
		text-align: center;	
		> div {
			margin: 0 auto; 
		}
	}


	.content {
		position: relative; 
		display: flex; 
		align-items: center;
		justify-content: center; 

		.huzzah {
			display: flex;
			align-items: center;
			justify-content: center;			
			> div {
				position: relative;
				text-align: center;
				font-size: 16px;
				line-height: 150%; 
				img { width: 490px; margin-bottom: 40px; }
				> div { width: 490px; }
			}
		}

	}	
	.footer {
		display: flex;
		padding: $size-gutter * 2;
		> div { flex: 1; }
	}
}
</style>