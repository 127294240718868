<template>
<div class='addTeam'>
	<div class='content'>

		<div class='wrapper mainTitle'>
			<div>
				<div>Welcome aboard {{$store.state.profile.firstname}}!</div>
				<div>To get started, please watch this Intro Video.</div>
			</div><img src='/img/faces/4.png' />
		</div>

		<div class='video'><div>
			<video ref='vid' playsinline src='/mp4/managerintro.mp4' @canplay='vidLoaded' crossOrigin='anonymous' />
			<transition name='zoom'>
				<div v-if='canPlay' class='playButton' @click='play'>
					<svg class='smallIcon'>
						<use xlink:href='#angleRight' />
					</svg>
				</div>
			</transition>
			<transition name='fade'>
				<Loading v-if='working' />
			</transition>				
		</div></div>

	</div>
	<div class='footer'>
		<div class='modalControls'>
			<div class='iconTextButton success' :class='{ disabled: !canClickNext }' @click='upNext'>
				<svg class='smallIcon'>
					<use xlink:href='#angleRight' />
				</svg>
				<span class='label'>Next: Create your first Team and onboard Staff...</span>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import Loading from '@/components/common/Loading'

export default {
	name: 'Welcome',
	components: { Loading },
	props: ['cb'],
	data() { return { working: true, canPlay: false, canClickNext: false }},
	methods: {
		upNext() {
			this.$emit("close")
			this.cb()
		},
		vidLoaded() {
			this.working = false
			this.canPlay = true
		},
		play() {
			this.$refs.vid.play()
			this.canPlay = false
			this.canClickNext = true
		}
	}
}
</script>

<style lang='scss'>
.addTeam {
	@include absTopLeft;
	@include fill;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr auto;

	.content {
		display: grid;
		grid-template-rows: auto 1fr;

		.wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.mainTitle {
			padding-top: 40px;
			font-size: 30px;
			margin: 0 auto;			
			img { width: 64px; margin: 0 $size-gutter * 3;}
			> div {
				margin: 0 auto; 
				text-align: center;
				> div:last-child { font-size: 16px; margin-top: 7px;  }
			}
		}

		.video {
			margin: 40px;
			position: relative;
			> div {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
			video { object-fit: contain; width: 100%; height: 100%; }
			.playButton { 
				position: absolute;
				z-index: 20;
				top: 50%;
				left: 50%;
				width: 120px;
				height: 120px;
				margin-top: -60px;
				margin-left: -60px;
				border-radius: 50%;
				background: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				box-shadow: 0 0 60px rgba(0,0,0,0.6);
				opacity: 0.93;
				transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
				> svg {
					color: $color-black;
					width: 60px;
					height: 60px;
				}
				&:after {
					position: absolute;
					top: -200%;
					left: -200%;
					width: 600%;
					height: 600%;
					content: '';
				}
				&:hover {
					transform: scale(1.2);
					background: $color-primary-accent;
					> svg { color: #fff; }
				}
			}
		}	
	}

	.footer {
		display: flex;
		padding: $size-gutter * 2;
		> div { flex: 1; }
	}
}
</style>