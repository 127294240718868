<template> 
<div class='bigTernaryToggle'>
	<div><slot /></div>
	<div><div>
		<div class='miniButton' :class='{ success: value==="readWrite" }' @click='$emit("input", "readWrite")'>Edit &amp; View</div>
		<div class='miniButton' :class='{ primary: value==="readOnly" }' @click='$emit("input", "readOnly")'>View Only</div>
		<div class='miniButton' :class='{ alert: value===false }' @click='$emit("input", false)'>Off</div>
	</div></div>
	<div>
		<template v-if='value==="readWrite"'>
			{{descEdit}}
		</template>
		<template v-else-if='value==="readOnly"'>
			{{descView}}
		</template>			
		<template v-else>
			{{descOff}}
		</template>														
	</div>
</div>
</template> 
 
<script>
export default {
	name: 'BigTernaryToggle',
	props: ['value','descEdit','descView','descOff']
}
</script>

<style lang='scss'>
.bigTernaryToggle {
	display: grid;
	grid-template-rows: auto 1fr auto;
	> div:nth-child(1) {
		padding: $size-gutter * 2;
		text-align: center;
		font-size: $size-font-standard;
		font-weight: bold;
		text-decoration: underline;
		color: $color-primary-accent;
	}
	> div:nth-child(2) {
		display: flex;
		justify-content: center;
		padding: 0 $size-gutter * 2;
		> div { flex: 1; }
		.miniButton { 
			margin-bottom: 1px; 
		}
	}
	> div:nth-child(3) {
		padding: $size-gutter * 2;
		text-align: center;
		font-size: $size-font-small;
		line-height: $size-lineHeight-small;
		font-style: italic;
	}	
}
</style>