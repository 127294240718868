<template>
<div class='addTeam'>
	<div class='content'>

		<transition :name='transitionDir'>

			<div v-if='step===1' class='step step1' :key='1'>

				<Scroll>

<!--
					<div v-if='first' class='wrapper header'>
						Welcome {{$store.state.profile.firstname}}!&nbsp;&nbsp;Let's create your First Team...
					</div>
					<div v-else class='wrapper header'>
						Create a New Team
					</div>				
-->

					<div class='wrapper title'>
						<div>Step 1 of 2:</div>
						<div>Choose a Name for your Team</div>
					</div>

					<div class='wrapper teamName'>
						<TextInput v-model='title' ref='teamName' maxlength='100' placeholder='Team Name...' />
					</div>

					<div class='wrapper info'><div>
						<div>
							<div>
								<div class='bullet'><svg><use xlink:href='#angleRight' /></svg></div>
								<div>Teams are used to organize Staff in SimpleSet (ex: locations, departments, etc)</div>
							</div>
							<div>
								<div class='bullet'><svg><use xlink:href='#angleRight' /></svg></div>
								<div>Staff on the same Team can instantly share information, including Clients</div>
							</div>
							<div>
								<div class='bullet'><svg><use xlink:href='#angleRight' /></svg></div>
								<div>You can create as many Teams as you need <img src='/img/faces/3.png' class='face' /></div>
							</div>
						</div>
					</div></div>					

				</Scroll>

			</div>

			<div class='step step2' v-else-if='step===2' ref='step2'  :key='2'>

				<Scroll>

					<div class='wrapper title'>
						<div>Step 2 of 2:</div>
						<div>Add Team Branding (Optional)</div>
					</div>
					<div class='wrapper branding'><div>
						<UploadLogo 
							:logo='logo' 
							@uploadSuccess='onUploadSuccess'
							@unsetLogo='unsetLogo'
						/>
						<div class='contactInfo'>
							<TextInput ref='contactInfo' v-model='headerl1' maxlength='100' :placeholder='`Line 1 (ex: ${title})`' />
							<TextInput v-model='headerl2' maxlength='100' placeholder='Line 2 (ex: Address)' />
							<TextInput v-model='headerl3' maxlength='100' placeholder='Line 3 (ex: Phone / Website)' />
						</div>
					</div></div>
					<div class='wrapper info'><div>
						<div>
							<div>
								<div class='bullet'><svg><use xlink:href='#angleRight' /></svg></div>
								<div>Branding appears in the emails you send to Clients, their Online Portal and on the exercise handouts</div>
							</div>
							<div>
								<div class='bullet'><svg><use xlink:href='#angleRight' /></svg></div>
								<div>Logos that are long rectangles work best (like the drop space provided)</div>
							</div>
							<div>
								<div class='bullet'><svg><use xlink:href='#angleRight' /></svg></div>
								<div>You can add up to three lines of Contact Info.  For example:  Your clinic address, phone number, website, etc.</div>
							</div>
							<div>
								<div class='bullet'><svg><use xlink:href='#angleRight' /></svg></div>
								<div>Technical details &mdash; Logo filetypes: JPG, PNG or WEBP, max 2MB</div>
							</div>							
						</div>
					</div></div>
				</Scroll>

			</div>

<!--
			<div class='step step3' v-else-if='step===3' ref='step3'  :key='3'>

				<Scroll>
					<div class='wrapper mainTitle'>
						<div>Add Team</div>
					</div>					
					<div class='wrapper title'>
						<div>Step 3 of 3:</div>
						<div>Add Team Contact Info (Optional)</div>
					</div>
					<div class='wrapper contactInfo'>
						<div>
							<TextInput ref='contactInfo' v-model='headerl1' maxlength='100' placeholder='Contact Line 1...' />
							<TextInput v-model='headerl2' maxlength='100' placeholder='Contact Line 2...' />
							<TextInput v-model='headerl3' maxlength='100' placeholder='Contact Line 3...' />
							<TextInput v-model='footer' maxlength='100' placeholder='Footer...' />
						</div>
					</div>

					<div class='wrapper info'><div>
						<div>
							<div>
								<div class='bullet'><svg><use xlink:href='#angleRight' /></svg></div>
								<div>Team Contact Info appears at the top of exercise programs, and on all correspondence with patients / clients.</div>
							</div>
							<div>
								<div class='bullet'><svg><use xlink:href='#angleRight' /></svg></div>
								<div>You can add up to three lines of Contact Info.  For example:  Your clinic address, phone number, website, etc.</div>
							</div>							
							<div>
								<div class='bullet'><svg><use xlink:href='#angleRight' /></svg></div>
								<div>The Team Footer is displayed at the bottom of exercise programs.</div>
							</div>							
						</div>
					</div></div>
				</Scroll>
			</div>
-->

		</transition>

	</div>
	<div class='footer'>
		<div class='modalControls'>
			<div class='iconTextButton neutral' :class='{ disabled: step===1 }' @click='back'>
				<svg class='smallIcon'>
					<use xlink:href='#angleLeft' />
				</svg>
				<span class='label'>Back</span>
			</div>		
			<div v-if='step<2' class='iconTextButton primary' :class='{ disabled: !nextEnabled }' @click='next'>
				<svg class='smallIcon'>
					<use xlink:href='#angleRight' />
				</svg>
				<span class='label'>Next: Step {{step+1}}</span>
			</div>
			<div v-else class='iconTextButton success' :class='{ disabled: !title }' @click='save'>
				<svg class='smallIcon'>
					<use xlink:href='#check' />
				</svg>
				<span class='label'>Done</span>
			</div>
		</div>
	</div>
	<transition name='fade'>
		<Loading v-show='working' />
	</transition>
</div>
</template>

<script>
import { teamsApi } from '@/services/api/modules/manager/teams'
import { staffApi } from '@/services/api/modules/manager/staff'
import Loading from '@/components/common/Loading'
import TextInput from '@/components/common/TextInput'
import Scroll from '@/components/common/Scroll'
import UploadLogo from './UploadLogo'

export default {
	name: 'AddTeam',
	components: { Loading, TextInput, Scroll, UploadLogo },
	props: ['cb','addManagerToTeam'],
	data: () => ({
		transitionDir: 'rtl',
		step: 1,
		title: '',
		logoId: null,
		logo: null,
		headerl1: '',
		headerl2: '',
		headerl3: '',
		footer: '',
		teamExercises: 'readWrite',
		teamTemplates: 'readWrite',
		teamEducation: 'readWrite',
		teamOutcomes: 'readWrite',
		teamClients: true,
		orgExercises: false,
		orgTemplates: false,
		orgEducation: false,
		orgOutcomes: false,
		teamHeader: true,
		teamSwap: false,
		ePHI: true,
		secondaryAuth: false,
		pendingUpload: null,
		working: false
	}),
	computed: {
		nextEnabled() { return this.step > 1 || this.title },
	},
	methods: {
		onUploadSuccess({ id, url }) {
			this.logo = url
			this.logoId = id 
		},
		unsetLogo() {
			this.logo = null
			this.logoId = null 			
		},
		back() {
			this.transitionDir='ltr'
			this.step--
		},
		next() {
			this.transitionDir='rtl'			
			this.step++
		},
		async save() {
			const payload = {
				title: this.title,
				teamClients: this.teamClients,
				teamSwap: this.teamSwap,
				allowPHI: this.ePHI,
				secondaryAuth: this.secondaryAuth,
				teamExercises: this.teamExercises,
				teamEducation: this.teamEducation,
				teamOutcomes: this.teamOutcomes,
				teamTemplates: this.teamTemplates,
				orgExercises: this.orgExercises,
				orgTemplates: this.orgTemplates,
				orgEducation: this.orgEducation,
				orgOutcomes: this.orgOutcomes,
				logoId: this.logoId,
				headerLine1: this.headerl1,
				headerLine2: this.headerl2,
				headerLine3: this.headerl3,
				footer: this.footer					
			}
			this.working = true
			try {
				const [err, result] = await teamsApi.touch('create', payload)
				if (!err) {
					if (this.addManagerToTeam) {
						await staffApi.touch('joinStaffTeam', { staffId: this.$store.state.profile.user.id, teamId: result.id })
					}
					await this.cb(result)
					this.$emit("close")
				}
			} finally {
				this.working = false
			}
		}
	},
	watch: {
		step(v) {
			if (v===1) setTimeout(()=>this.$refs.teamName.focus(), 0)
			if (v===3) setTimeout(()=>this.$refs.contactInfo.focus(), 0)
		//	if (v===2) setTimeout(()=>this.$refs.step2.scrollIntoView({ behavior: 'smooth' }), 0)
		//	if (v===3) setTimeout(()=>this.$refs.step3.scrollIntoView({ behavior: 'smooth' }), 0)
		//	if (v===4) setTimeout(()=>this.$refs.step4.scrollIntoView({ behavior: 'smooth' }), 0)
		}
	},
	mounted() {
		if (this.$store.state.teams.list.length === 1) {
			this.orgExercises = "readOnly"
			this.orgTemplates = "readOnly"
			this.orgEducation = "readOnly"
			this.orgOutcomes = "readOnly"
		}
		if (this.$refs.teamName) this.$refs.teamName.focus()
	}
}
</script>

<style lang='scss'>
.addTeam {
	@include absTopLeft;
	@include fill;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr auto;

	.content {
		position: relative; 

		.wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.header {
			padding: 40px 0;
			font-size: 30px;
			color: $color-primary-accent;
		}

		.info {
			width: 600px;
			margin: 0 auto;
			border-radius: 7px;
			font-size: $size-font-large;
			line-height: $size-lineHeight-large;	
			.face { margin-left: $size-gutter; height: 20px; }			
			> div {
				margin: 0 auto;
				> div > div { 
					display: flex;
					align-items: flex-start;
					margin: 14px 0;
					&:first-child { margin-top: 0; }
					&:last-child { margin-bottom: 0; }
					> div { display: flex; align-items: center; }
				}
				.bullet {
					width: 24px;
					height: 24px;
					min-width: 24px;
					border-radius: 50%;
					margin-right: $size-gutter * 2;
					background: $button-gradient-primary;
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;
					top: 1px;
					svg { width: 16px; height: 16px; color: #fff; }
				}
			}
		}

		.teamName > .textInput { 
			width: 600px;
			height: 50px;
			border: 1px solid $color-neutral-shadow; 
			box-shadow: 0 4px 6px -2px rgba(0,0,0,.08);	
			margin-bottom: 40px;
		}

		.branding > div { 
			width: 700px;
			margin-bottom: 40px;
			display: flex;
			align-items: center;
		}

		.uploadTeamLogo {
			width: 240px; height: 152px; 
			margin-right: 20px;
		}

		.contactInfo {
			flex: 1;
			border: 1px solid $color-neutral-shadow;
			> div {
				box-shadow: 0 4px 6px -2px rgba(0,0,0,.08);	
				border: 1px solid $color-neutral-shadow;
			}
			.textInput { height: 50px; }
		}

		.step {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%; 

			.mainTitle {
				padding-top: 40px;
				font-size: 30px;
				width: 600px;
				margin: 0 auto;			
				> div {
					margin: 0 auto; 
				}
			}

			.title {
				padding-top: 50px;
				padding-bottom: 50px;
				> div:first-child {
					font-size: 30px;
					color: $color-primary-accent;
					font-weight: bold;
				}
				> div:last-child {
					margin-top: 4px;
					margin-left: $size-gutter * 2;
					font-size: 24px;
				}
			}		
		}

	}

	.footer {
		display: flex;
		padding: $size-gutter * 2;
		> div { flex: 1; }
	}
}
</style>