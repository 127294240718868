<template>
<SmallModal class='staffPicker' @close='$emit("close")'>
	<template v-slot:title>Choose a new Primary Contact</template>
	<template v-slot:message>
		<SearchInput 
			v-model='search'
			@input='debouncedFetch'
			@submit='fetchTop'
			placeholder='Filter Staff...'
			ref='searchInput'
		/>
		<div class='staff'>
			<div v-for='(item, index) in vSelectStaff' :key='index' @click='selected=item.id' :class='{ selected: selected===item.id }'>
				{{item.label}}
			</div>
		</div>
		<transition name='fade'>
			<Loading v-show='loading' />
		</transition>
	</template>
	<template v-slot:buttons>
		<div class='iconTextButton success' v-if='selected' @click='done'>
			<svg class='smallIcon'>
				<use xlink:href='#check' />
			</svg>			
			<span class='label'>Done</span>
		</div>
	</template>
</SmallModal>
</template>

<script>
import { debounce } from 'lodash'	
import { staffApi } from '@/services/api/modules/manager/staff'
import SmallModal from '@/components/common/modals/SmallModal'
import SearchInput from '@/components/common/SearchInput'
import Loading from '@/components/common/Loading'

export default {
	name: 'StaffPicker',
	components: { SmallModal, SearchInput, Loading },
	props: ['staffAccount','fn'],
	data() { return {
		staff: [],
		selected: null,
		loading: false,
		search: '',
		debouncedFetch: debounce(function(search) {
			this.search = search 
			this.fetchTop()
		}.bind(this), 1000, { leading: false, trailing: true })	
	}},
	computed: {
		vSelectStaff() {
			return this.staff
				.filter(row => row.id !== this.staffAccount.id)
				.map(row => ({
					id: row.id,
					label: `${row.firstname} ${row.lastname} (${row.email})`
				}))
		}
	},
	methods: {
		async fetchTop() {
			this.debouncedFetch.flush()
			this.loading = true
			const [err, result] = await staffApi.touch('fetch', { 
				teamId: this.staffAccount.teamId, 
				limit: 50,
				search: this.search,
				enabled: true,
				disabled: false
			})
			this.loading = false
			if (!err) {
				this.staff = result.items
			}
		},
		done() {
			this.fn(this.selected)
			this.$emit('close')
		}
	},
	mounted() {
		this.fetchTop()
		this.$refs.searchInput.focus()
	}
}
</script>

<style lang='scss'>
.staffPicker {
	b { color: $color-primary-accent; }
	.staff {
		margin-top: $size-gutter * 2;
		> div {
			display: flex;
			align-items: center;
			font-size: $size-font-standard;
			padding: $size-gutter $size-gutter * 2;
			cursor: pointer;
			&.selected {
				font-weight: bold;
				color: $color-primary-accent;
			}
		}
	}
}
</style>