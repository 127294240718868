<template>
<div class='teamSettingsModal' :class='{ locked: team.locked }'>
	<div class='content'>
		<Scroll>

			<div class="grid">

				<div class='section' :class='{ open: section1 }'>
					<div class='head' @click='toggle("section1")'>
						<div class='angle'>
							<div>
								<svg class='smallIcon'>
									<use xlink:href='#angleRight' />
								</svg>			
							</div>
						</div>
						<div class='title'>Team Name</div>
					</div>
					<div v-if='section1' class='body teamName'>
						<TextInput v-model='title' maxlength='100' placeholder='Team Name...' />
					</div>
				</div>

				<div class='section' :class='{ open: section2 }'>
					<div class='head' @click='toggle("section2")' >
						<div class='angle'>
							<div>
								<svg class='smallIcon'>
									<use xlink:href='#angleRight' />
								</svg>			
							</div>
						</div>
						<div class='title'>Logo</div>
					</div>
					<div v-if='section2' class='body logo'>
						<UploadLogo 
							:logo='logo' 
							@uploadSuccess='onUploadSuccess'
							@unsetLogo='unsetLogo'
						/>
					</div>
				</div>


				<div class='section' :class='{ open: section3 }'>
					<div class='head' @click='toggle("section3")' >
						<div class='angle'>
							<div>
								<svg class='smallIcon'>
									<use xlink:href='#angleRight' />
								</svg>			
							</div>
						</div>
						<div class='title'>Contact Info</div>
					</div>
					<div v-if='section3' class='body contactInfo'>
						<div>
							<TextInput v-model='headerl1' maxlength='100' placeholder='Contact Line 1...' />
							<TextInput v-model='headerl2' maxlength='100' placeholder='Contact Line 2...' />
							<TextInput v-model='headerl3' maxlength='100' placeholder='Contact Line 3...' />
							<TextInput v-model='footer' maxlength='100' placeholder='Footer...' />
						</div>
					</div>
				</div>

				<div class='section' :class='{ open: section4 }'>
					<div class='head' @click='toggle("section4")' >
						<div class='angle'>
							<div>
								<svg class='smallIcon'>
									<use xlink:href='#angleRight' />
								</svg>			
							</div>
						</div>
						<div class='title'>Client Portal Outgoing Link</div>
					</div>
					<div v-if='section4' class='body portalLink'>
						<p>Add a custom link to your clients' Online Portal.  For example, a link to your website, your blog, your scheduling system, etc.</p>
						<div>
							<TextInput v-model='portalLink.title' maxlength='32' placeholder='Link Title...' :class='{ err: portalLink.errTitle }' @input="portalLink.errTitle=false" />
							<TextInput v-model='portalLink.url' placeholder='Link URL...' :class='{ err: portalLink.errUrl }' @input="portalLink.errUrl=false" />
						</div>
						<a 
							class='iconTextButton tagSuccess'
							:href='portalLink.url'
							target='_blank'
							v-if="portalLink.id"
						>
							<svg class='smallIcon'>
								<use xlink:href='#link' />
							</svg>
							<span class='label'>{{portalLink.title}}: {{portalLink.url}}</span>
						</a>
						<p><b>Note:</b> This link is the same for ALL teams.</p>
						<div 
							class='iconTextButton neutral'
							v-if="portalLink.id"
							@click="clearLink"
						>
							<svg class='smallIcon'>
								<use xlink:href='#cancel' />
							</svg>
							<span class='label'>Remove Link</span>
						</div>
					</div>
				</div>


	<!--
				<div class='section' :class='{ open: section4 }'>
					<div class='head' @click='toggle("section4")' >
						<div class='angle'>
							<div>
								<svg class='smallIcon'>
									<use xlink:href='#angleRight' />
								</svg>			
							</div>
						</div>
						<div class='title'>Permissions</div>
					</div>

					<div v-if='section4' class='body perms'>

						<div class='desc'><div>Team Sharing</div></div>

						<div class='settings'><div>
							<TernaryToggle 
								v-model='teamExercises'
								descEdit='Clinicians can view and modify Exercises in the Shared Team Folder.'
								descView='Clinicians can view, but not modify, Exercises in the Shared Team Folder.'
								descOff='Clinicians cannot access Exercises in the Shared Team Folder.'
							>Exercises</TernaryToggle>
							<TernaryToggle 
								v-model='teamFolders'
								descEdit='Clinicians can view and modify Templates in the Shared Team Folder.'
								descView='Clinicians can view, but not modify, Templates in the Shared Team Folder.'
								descOff='Clinicians cannot access Templates in the Shared Team Folder.'
							>Templates</TernaryToggle>
							<TernaryToggle 
								v-model='teamEducation'
								descEdit='Clinicians can view and modify Education Items in the Shared Team Folder.'
								descView='Clinicians can view, but not modify, Education Items in the Shared Team Folder.'
								descOff='Clinicians cannot access Education Items in the Shared Team Folder.'
							>Education</TernaryToggle>
							<TernaryToggle v-model='teamOutcomes'
								descEdit='Clinicians can view and modify Outcomes in the Shared Team Folder.'
								descView='Clinicians can view, but not modify, Outcomes in the Shared Team Folder.'
								descOff='Clinicians cannot access Outcomes in the Shared Team Folder.'
							>Outcomes</TernaryToggle>
							<BinaryToggle 
								v-model='teamClients'
								onDesc='Clients are shared with other Clinicians on the Team.'
								offDesc='No Client sharing.'
							>Clients</BinaryToggle>
						</div></div>

						<div class='desc'><div>Organization Sharing</div></div>

						<div class='settings'><div>
							<TernaryToggle 
								v-model='orgExercises'
								descEdit='Clinicians can view and modify Exercises in the Shared Organization Folder.'
								descView='Clinicians can view, but not modify, Exercises in the Shared Organization Folder.'
								descOff='Clinicians cannot access organization Exercises in the Shared Organization Folder.'
							>Exercises</TernaryToggle>
							<TernaryToggle 
								v-model='orgFolders'
								descEdit='Clinicians can view and modify Templates in the Shared Organization Folder.'
								descView='Clinicians can view, but not modify, Templates in the Shared Organization Folder.'
								descOff='Clinicians cannot access Templates in the Shared Organization Folder.'
							>Templates</TernaryToggle>
							<TernaryToggle 
								v-model='orgEducation'
								descEdit='Clinicians can view and modify Education Items in the Shared Organization Folder.'
								descView='Clinicians can view, but not modify, Education Items in the Shared Organization Folder.'
								descOff='Clinicians cannot access Education Items in the Shared Organization Folder.'
							>Education</TernaryToggle>
							<TernaryToggle v-model='orgOutcomes'
								descEdit='Clinicians can view and modify Outcomes in the Shared Organization Folder.'
								descView='Clinicians can view, but not modify, Outcomes in the Shared Organization Folder.'
								descOff='Clinicians cannot access Outcomes in the Shared Organization Folder.'
							>Outcomes</TernaryToggle>
						</div></div>

						<div class='desc'><div>Other</div></div>

						<div class='settings'><div>
							<BinaryToggle 
								v-model='teamSwap'
								onDesc='Multiple Clinicians can easily switch between accounts (useful on shared terminals).'
								offDesc='Clinicians must log in and out each time they use SimpleSet.'
							>Swap Accounts</BinaryToggle>
							<BinaryToggle 
								v-model='ePHI'
								onDesc='Clinicians can view and create patient / client profiles.'
								offDesc='Clinicians cannot view or create patients / clients.'
							>Client Manager</BinaryToggle>
							<BinaryToggle 
								v-model='secondaryAuth'
								onDesc='Clinicians receive a 2FA code if logging in from a location SimpleSet does not recognizze.'
								offDesc='No 2FA.'
							>2FA</BinaryToggle>	
						</div></div>

					</div>

				</div>
	-->

				<div class='section' :class='{ open: section5 }'>
					<div class='head' @click='toggle("section5")' >
						<div class='angle'>
							<div>
								<svg class='smallIcon'>
									<use xlink:href='#angleRight' />
								</svg>			
							</div>
						</div>
						<div class='title'>Lock</div>
					</div>
					<div v-if='section5' class='body lock'>
						<div>
							<DraggableSwitch 
								:value='locked'
								:clickable='true'
								@input='v=>locked=v'
							/>
							<template v-if='locked'><span class='locked'>Team is locked.  Permissions cannot be changed.</span></template>
							<template v-else>Team is unlocked.  Permissions are editable.</template>
						</div>
						<div class='desc'></div>
					</div>
				</div>

			</div>

		</Scroll>
	</div>
	<div class='footer'>
		<div class='modalControls'>
			<CancelButton @click='$emit("close")' />
			<div v-if='canSave' class='iconTextButton success' @click='save'>
				<svg class='smallIcon'>
					<use xlink:href='#check' />
				</svg>
				<span class='label'>Save</span>
			</div>
		</div>
	</div>
	<transition name='fade'>
		<Loading v-show='working' />
	</transition>
</div>
</template>

<script>
import { teamsApi } from '@/services/api/modules/manager/teams'
import { portalLinkApi } from '@/services/api/modules/manager/portalLink'
import Loading from '@/components/common/Loading'
import TextInput from '@/components/common/TextInput'
import Scroll from '@/components/common/Scroll'
import CancelButton from '@/components/common/buttons/Cancel'
import DraggableSwitch from '@/components/common/DraggableSwitch'
import TernaryToggle from './BigTernaryToggle'
import BinaryToggle from './BigBinaryToggle'
import UploadLogo from './UploadLogo'

export default {
	name: 'AddTeam',
	components: { Loading, TextInput, Scroll, TernaryToggle, BinaryToggle, UploadLogo, CancelButton, DraggableSwitch },
	props: ['teamId', 'cb'],
	data: () => ({
		section1: false,
		section2: false,
		section3: false,
		section4: false,
		section5: false,
		title: '',
		logoId: null,
		logo: null,
		headerl1: '',
		headerl2: '',
		headerl3: '',
		footer: '',
	/*
		teamExercises: 'readWrite',
		teamTemplates: 'readWrite',
		teamEducation: 'readWrite',
		teamOutcomes: 'readWrite',
		teamClients: true,
		orgExercises: false,
		orgFolders: false,
		orgEducation: false,
		orgOutcomes: false,
		teamHeader: true,
		teamSwap: false,
		ePHI: true,
		secondaryAuth: true,
	*/
		locked: false,
		pendingUpload: null,

		portalLink: {
			title: "",
			url: "",
			id: null,
			errTitle: false,
			errUrl: false
		},
		working: false,
		saved: '',
		savedLink: '',
	//	canSave: false
	}),
	computed: {
		nextEnabled() { return this.step > 1 || this.title },
		team() { return this.$store.state.teams.teams[this.teamId] },
		payload() {
			if (this.locked !== this.team.locked && !this.locked) {
				return {
					id: this.team.id,
					locked: this.locked
				}
			} else {
				return {
					id: this.team.id,
					title: this.title,
					teamHeaderFooter: this.teamHeader,
				/*
					teamClients: this.teamClients,
					teamSwap: this.teamSwap,
					restrictPhi: !this.ePHI,
					secondaryAuth: this.secondaryAuth,
					teamExercises: this.teamExercises,
					teamEducation: this.teamEducation,
					teamOutcomes: this.teamOutcomes,
					teamFolders: this.teamFolders,
					orgExercises: this.orgExercises,
					orgFolders: this.orgFolders,
					orgEducation: this.orgEducation,
					orgOutcomes: this.orgOutcomes,
				*/
					locked: this.locked,
					logoId: this.logoId,
					headerLine1: this.headerl1,
					headerLine2: this.headerl2,
					headerLine3: this.headerl3,
					footer: this.footer
				}
			}
		},
		canSaveTeam() { return JSON.stringify(this.payload)!==this.saved  },
		canSaveLink() { return JSON.stringify(this.portalLink)!==this.savedLink },
		canSave() {
			return this.canSaveTeam || this.canSaveLink
		}
	},
	methods: {
		toggle(section) {
			if (!this.team.locked) this[section] = !this[section]
		},
		onUploadSuccess({ id, url }) {
			this.logo = url
			this.logoId = id 
		},
		unsetLogo() {
			this.logo = null
			this.logoId = null 			
		},

		validateLink() {
			if (!this.portalLink.title) this.portalLink.errTitle = true
			try {
				new URL(this.portalLink.url)
			} catch (err) {
				this.portalLink.errUrl = true
			}
			return !this.portalLink.errTitle && !this.portalLink.errUrl
		},

		async save() {

			const promises = []

			if (this.canSaveLink) {
				if (!this.validateLink()) return
				const payload = { title: this.portalLink.title, url: this.portalLink.url }
				if (this.portalLink.id) {
					promises.push(portalLinkApi.touch('updatePortalLink', payload))
				} else {
					promises.push(portalLinkApi.touch('createPortalLink', payload))
				}
			}

			if (this.canSaveTeam) {
				promises.push(teamsApi.touch('update', this.payload))
			}

			if (promises.length) {
				try {
					this.working = true
					await Promise.all(promises)
					await this.cb()
					this.$emit("close")
				} finally {
					this.working = false
				}
			}
		},

		async clearLink() {
			try {
				this.working = true
				const [err] = await portalLinkApi.touch('destroyPortalLink')
				if (!err) {
					this.portalLink = {
						title: "",
						url: "",
						id: null,
						errTitle: false,
						errUrl: false
					}
					this.savedLink = JSON.stringify(this.portalLink)
					this.$store.dispatch('flash/showAction', 'Link Removed')
				}
			} finally {
				this.working = false
			}
		},
	},
	watch: {
		step(v) {
			if (v===2) setTimeout(()=>this.$refs.step2.scrollIntoView({ behavior: 'smooth' }), 0)
			if (v===3) setTimeout(()=>this.$refs.step3.scrollIntoView({ behavior: 'smooth' }), 0)
			if (v===4) setTimeout(()=>this.$refs.step4.scrollIntoView({ behavior: 'smooth' }), 0)
		},
	/*
		payload: {
			handler() {
				this.canSave = JSON.stringify(this.payload)!==this.saved
			},
            deep: true
		}
	*/
	},
	mounted() {
		this.title = this.team.title
		this.logoId = this.team.logoId
		this.logo = this.team.logo
		this.headerl1 = this.team.headerLine1
		this.headerl2 = this.team.headerLine2
		this.headerl3 = this.team.headerLine3
		this.footer = this.team.footer
	/*
		this.teamExercises = this.team.teamExercises
		this.teamFolders = this.team.teamFolders
		this.teamEducation = this.team.teamEducation
		this.teamOutcomes = this.team.teamOutcomes
		this.teamClients = this.team.teamClients
		this.orgExercises = this.team.orgExercises
		this.orgFolders = this.team.orgFolders
		this.orgEducation = this.team.orgEducation
		this.orgOutcomes = this.team.orgOutcomes
		this.teamHeader = this.team.teamHeaderFooter
		this.teamSwap = this.team.teamSwap
		this.ePHI = !this.team.restrictPhi
		this.secondaryAuth = this.team.secondaryAuth
	*/
		this.locked = this.team.locked
		this.saved = JSON.stringify(this.payload)

		if (this.team.locked) {
			this.section5 = true
		} else {
			this.section1 = true
			this.section2 = true
			this.section3 = true
			this.section4 = true
			this.section5 = true
		}


		portalLinkApi.touch('fetchPortalLink')
			.then(([err, result])=> {
				if (!err) {
					Object.assign(this.portalLink, result)
					this.savedLink = JSON.stringify(this.portalLink)
				}
			})
			.catch(()=>{})
	}
}
</script>

<style lang='scss'>
.teamSettingsModal {
	@include absTopLeft;
	@include fill;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr auto;

	&.locked .section:not(.open) { opacity: 0.5; pointer-events: none; }

	.content {
		position: relative; 

		.grid { 
			padding-top: 28px;
			display: grid;
			grid-template-columns: 1fr 1fr;
		}


		.section { 
			padding: 0 28px; 
			padding-bottom: 14px;
			&.open {
				.angle { transform: rotate(90deg); }
			}
		}

		.head {
			display: flex;
			cursor: pointer;
			margin-bottom: $size-gutter * 2;

			.angle {
				width: 24px;
				height: 24px;
				background: $button-gradient-primary;
				color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%; 
				margin-right: $size-gutter * 2;
				transition: transform 200ms linear;
				svg {
					position: relative;
					top: 1px;
				}
			}

			.title {
				flex: 1;
				display: flex;
				align-items: center;
				font-size: $size-font-large;
				font-weight: bold;
				color: $color-primary-accent;
			}

		}

		.body {
			margin-left: 38px; 	
			animation: slideDown 600ms $ease-out-quint;		
			margin-bottom: 14px;
		}

		.teamName > .textInput { 
			width: 100%;
			border: 1px solid $color-neutral-shadow; 
		}

		.logo > div { 
			width: 300px; height: 160px; 
		}

		.contactInfo {
			> div {
				border: 1px solid $color-neutral-shadow;
			}
		}

		.portalLink {
			> p { 
				font-size: $size-font-standard;
				line-height: $size-lineHeight-standard;
				padding: 0 $size-gutter * 2;
			}
			> div {
				border: 1px solid $color-neutral-shadow;
			}			
			div.iconTextButton { width: 50%; }
			a { width: 100%; }
			svg { color: $color-black; }
			.textInput.err {
				color: $color-alert;
				&::placeholder { color: $color-alert !important; }
			}
		}

		.desc {
			font-size: $size-font-large;
			padding: $size-gutter * 2;
			line-height: 150%;
		}

		.lock > div:first-child {
			display: flex;
			align-items: center;
			justify-content: left;
			font-size: $size-font-standard;
			> div { margin-right: $size-gutter * 2; }
			.locked { color: $color-alert; font-weight: bold; }
		}
		

		.settings > div {
			gap: 7px;
			display: flex;
			> div {
				width: 160px;
				background: $color-neutral-silver;
				border-radius: 7px;
			}
			margin-bottom: 14px;
		}

	}

	.footer {
		display: flex;
		padding: $size-gutter * 2;
		> div { flex: 1; }
	}
}
</style>