import { render, staticRenderFns } from "./TeamChooser.vue?vue&type=template&id=0df9d57e&"
import script from "./TeamChooser.vue?vue&type=script&lang=js&"
export * from "./TeamChooser.vue?vue&type=script&lang=js&"
import style0 from "./TeamChooser.vue?vue&type=style&index=0&id=0df9d57e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports