<template>
<div class='pendingStaff'>
	<transition name='ltrtl'>
		<div v-if='visible && !closed' class='wrapper'>
			<div>
				<div class='content'>

					<Scroll>

						<div class='header'>
							<div>Awaiting RSVP<template v-if='list.length>1'>s</template>: <span>{{list.length}}</span></div>
							<div class='angle' @click='closed=true'>
								<div>
									<svg class='smallIcon'>
										<use xlink:href='#angleRight' />
									</svg>			
								</div>
							</div>
						</div>

						<div class='searchWrapper'>
							<SearchInput 
								v-model='search'
								@input='debouncedFetch'
								@submit='fetchTop'
								placeholder='Filter Emails...'
							/>	
						</div>			

						<div class='rsvps'>
							<div v-for='(rsvp, i) in list' :key='i' class='rsvp'>
								<div class='email'>
									<div>{{rsvp.email}}</div>
									<div>
										<span class='status' :class='[rsvp.status]'>
											<template v-if="rsvp.status==='delivery'">Delivered</template>
											<template v-if="rsvp.status==='open'">Opened</template>
											<template v-if="rsvp.status==='click'">Clicked</template>
											<template v-else-if="rsvp.status==='bounce'">Bounced</template>
											<template v-else-if="rsvp.status==='complaint'">Marked as Spam</template>
										</span>									
										{{getTeam(rsvp.teamId)}}
									</div>
								</div>
								<div>
									<div class='days'>Days old: {{getDays(rsvp.created)}}</div>
									<div class='miniButton' @click='destroy(rsvp.id)'>Revoke</div>
								</div>
							</div>
						</div>

						<AtBottom v-if='list.length<total' @atBottom='fetchNext' />	

					</Scroll>

				</div>

				<transition name='fade'>
					<Loading v-if='loading' />
				</transition>	

			</div>
		</div>
	</transition>
	<div v-if='closed' class='closedAngle' @click='closed=false'>
		<div />
		<div>{{list.length}}</div>
	</div>	
</div>
</template>

<script>
import { debounce } from 'lodash'
import { UI } from '@/utilities'
import { accumulate } from '@/store/helpers'
import { dateTime } from '@/components/common/mixins/dateTime'
import { teamInvitationsApi } from '@/services/api/modules/manager/teamInvitations'
import AtBottom from '@/components/common/AtBottom'
import SearchInput from '@/components/common/SearchInput'
import Loading from '@/components/common/Loading'
import Scroll from '@/components/common/Scroll'

export default {
	name: 'PendingStaff',
	mixins: [dateTime],
	components: { AtBottom, SearchInput, Loading, Scroll },
	props: [],
	data() { return {
		ui: null,
		items: {},
		search: '',
		loading: false,
		total: null,
		visible: false,	
		closed: false,	
		debouncedFetch: debounce(function(search) {
			this.search = search 
			this.fetchTop()
		}.bind(this), 1000, { leading: false, trailing: true })			
	}},
	computed: {
		list() { return Object.values(this.items).sort((a,b)=>b.id-a.id) },		
	},
	methods: {
		getTeam(teamId) {
			if (this.$store.state.teams.teams[teamId]) return this.$store.state.teams.teams[teamId].title
			else return null
		},
		getDays(ts) {
			const diffDays = parseInt((new Date() - new Date(ts)) / (1000 * 60 * 60 * 24), 10)
			return diffDays
		},
		async init() {
			this.search = ''
			await this.fetchTop()
			if (this.list.length) this.visible = true
			else this.visible = false
		},
		async fetchTop() {
			this.debouncedFetch.flush()
			this.loading = true
			try {
				const [err, result] = await teamInvitationsApi.touch('fetch', { 
					sort: 'id', 
					sortDir: 'desc', 
					limit: 50,
					search: this.search
				})
				//console.log('here', result)
				if (!err) {
					this.total = result.total
					this.items = accumulate(result.items)
				}
			} finally {
				this.loading = false
			}
		},
		async fetchNext() {
			if (this.list.length===this.total) return
			this.loading = true
			try {
				const [err, result] = await teamInvitationsApi.touch('fetch', { 
					sort: 'id', 
					sortDir: 'desc', 
					skip: this.list.length, 
					limit: 50,
					search: this.search
				})
				if (!err) {
					this.items = Object.assign({}, this.items, accumulate(result.items))
				}
			} finally {
				this.loading = false
			}
		},
		async destroy(id) {
			this.loading = true
			try {
				const [err, result] = await teamInvitationsApi.touch('destroy', { id })
				if (!err) {
					this.$delete(this.items, id)
					this.$store.commit('profile/incOrgInvitations', 1)
					if (this.search && !Object.keys(this.items).length) {
						const [err, result] = await teamInvitationsApi.touch('fetch')
						if (!err && result.total===0) {
							this.visible = false
						}
					} else if (!Object.keys(this.items).length) {
						this.visible = false
					}
				}
			} finally {
				this.loading = false 
			}
		}
	},
	watch: {
		closed(v) {
			this.ui.set('pendingStaffClosed', v)				
		}
	},
	mounted() { 
		this.ui = new UI(this.$store.state.profile.user.id)
		const closed = this.ui.get('pendingStaffClosed')	
		if (typeof closed==='boolean') this.closed = closed
		this.init()
	}
}
</script>

<style lang='scss'>
.pendingStaff {	
	.wrapper {
		width: 280px;
		padding: 0 !important;
		height: 100%;
		> div {
			width: 100%;
			height: 100%;
			position: relative;
		}
	}
	
	&:has(>div) {
		margin-right: 1px;
	}
	

	.content {
		position: relative;
		height: 100%;
	}

	.header {
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: $size-font-standard;
		margin-top: $size-gutter * 2;
		margin-bottom: $size-gutter * 2;		
		padding: 0 $size-gutter * 3;
		span { margin-left: $size-gutter; font-weight: bold; color: $color-primary-accent; }
		.angle {
			cursor: pointer;
			flex: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: transform 200ms linear;		
			flex-basis: 32px;
			margin-left: auto;
			svg { transform: rotate(180deg); }
			> div {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 32px;
				height: 32px;
				border-radius: 50%;
				svg { color: #fff; }
				background: $color-primary-accent;
			}				
		}


	}

	.searchWrapper {
		margin: 0 $size-gutter * 3;
		border: 1px solid $color-neutral-shadow;
		margin-bottom: $size-gutter * 2;
	}

	.rsvps {

		> div {
			min-height: $size-control-height;
			display: flex;
			align-items: center;
			padding-right: $size-gutter * 3;
			padding-left: $size-gutter * 3;
			padding-top: $size-gutter; padding-bottom: $size-gutter;
			&:hover { background: $color-focus; }
		}

		.email { 
			min-width: 0;
			margin-right: $size-gutter * 2;
			flex: 1;
			/*
			> div {
				overflow: hidden;			
				text-overflow: ellipsis;
				white-space: nowrap;
				height: 24px;
				border-radius: 12px;
				background: $button-gradient-primary;
				padding: 0 $size-gutter * 2;
				line-height: 24px;
				font-size: $size-font-small;
				font-weight: bold;
				color: #fff;		
			}
			*/
			font-size: $size-font-small;
			line-height: $size-lineHeight-small;
			> div:first-child {
				color: $color-primary-accent;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			> div:last-child {
			}
			.status {
				&.send { color: $color-primary-accent; }
				&.delivery, &.open, &.click  { color: $color-success; }
				&.bounce, &.complaint { color: $color-alert;  }
			}
		}		

		.days { text-align: center; }
		.miniButton { 
			border-radius: 12px;
			padding-left: $size-gutter * 2;
			padding-right: $size-gutter * 2;
			background: #fff;
			transform: scale(.9);
			font-size: $size-font-standard;
			margin-top: 5px;
			border: 1px solid $color-neutral-shadow;
			font-weight: normal;
		}

	}

		.closedAngle {
			position: absolute; 
			top: 28px;
			left: 0;
			cursor: pointer;
			flex: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: transform 200ms linear;		
			margin-left: auto;
			z-index: 100;
			> div:first-child {
				position: absolute;
				right: 0;
				top: 0;
				z-index: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 32px;
				height: 32px;
				border-radius: 50%;
				background: $color-alert-accent;
			}	
			> div:last-child {
				position: relative;
				z-index: 10;
				background: $color-alert-accent;
				padding: 3px 6px;
				border-radius: 2px;
				font-weight: bold;
				color: #fff;
				font-size: $size-font-standard;
				margin-right: $size-gutter * 1.5;
				margin-left: $size-gutter * 1;
				margin-top: 5px;
			}	
			&:before {
				position: absolute;
				top: 0;
				left: -16px;
				width: 100%;
				height: 32px;
				content: '';
				background: $color-alert-accent;
			}		
		}

}
</style>